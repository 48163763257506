import {useDispatch, useSelector} from "react-redux";
import {selectAllPropPresets, selectSelectedPropPresetId, updateSelectedPropPreset} from "../../app/slices/TrainingSlice";
import PropertiesColumnHeader from "../properties_column/PropertiesColumnHeader";
import ActorPresetEditorField from "../actor_presets/ActorPresetEditorField";
import {FormatColorText} from "@mui/icons-material";
import PiInputfield from "../../components/PiInputField";
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import usePropHelper from "../../hooks/PropHelper";
import {Prop} from "../../models/Prop";
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import {useTranslation} from "react-i18next";
import {selectPropCategories, selectPropInteractions} from "../../app/slices/PropLibrarySlice";
import PropLibraryCategoryLabel from "../prop_library/PropLibraryCategoryLabel";
import PropLibraryInteractionLabel from "../prop_library/PropLibraryInteractionLabel";

export default function PropPresetEditor() {
    const selectedPropPreset = useSelector(selectSelectedPropPresetId);
    const propPresets = useSelector(selectAllPropPresets);
    const selectedProp = propPresets.find(prop => prop.id === selectedPropPreset);
    const {getPropDTO} = usePropHelper();
    const propDTO = selectedProp ? getPropDTO(selectedProp?.propId) : undefined;
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const categories = useSelector(selectPropCategories);

    const myCategoriesMapped = propDTO?.propContent.categories.map(category => {
        var result = categories.find(cat => cat.id === category.id);
        if (!result) return;
        return <PropLibraryCategoryLabel category={result} key={result.id}/>
    });

    const myInteractionsMapped = propDTO?.propContent.interactions.map(interaction => {
        return <PropLibraryInteractionLabel interactionName={interaction.name} key={interaction.id}/>
    });

    const updateSelectedProp = (field: keyof Prop, value: any) => {
        if (!selectedProp) return;
        const cpy: Prop = {...selectedProp, [field]: value};
        dispatch(updateSelectedPropPreset(cpy));
    }

    return <div className={"h-full flex flex-col"}>
        <div className="flex-col">
            <PropertiesColumnHeader text="Editing Prop Preset"/>
        </div>
        <div aria-label="actor-preset-window-root" className="flex flex-col h-full gap-4 overflow-y-auto pit-creator-scrollbar">
            {selectedProp &&
                <div className="flex h-full gap-[10px] p-[28px]">
                    <div className={"min-w-[500px] w-[500px]"} aria-label={"prop-preset-editor-col-1 h-full"}>
                        <ActorPresetEditorField name={t("prop_preview")} icon={ViewInArOutlinedIcon}>
                            <div className={"w-full h-full flex p-[40px]"}>
                                <img className={"m-auto object-contain"} src={propDTO?.propContent.downloadUri} alt={"Prop Thumbnail"}/>
                            </div>
                        </ActorPresetEditorField>
                    </div>
                    <div aria-label={"prop-preset-editor-col-2"} className={"flex flex-col gap-[20px] w-full h-[132px]"}>
                        <ActorPresetEditorField name={t("prop_name")} icon={ViewInArOutlinedIcon}>
                            <PiInputfield label="" onChange={(newValue) => updateSelectedProp("name", newValue)} placeholder="Working name..." value={selectedProp?.name}/>
                        </ActorPresetEditorField>
                        <ActorPresetEditorField name={t("categories")} icon={CategoryOutlinedIcon}>
                            <div className={"flex gap-[10px] px-[10px] py-[20px]"}>
                                {myCategoriesMapped}
                            </div>
                        </ActorPresetEditorField>
                        <ActorPresetEditorField name={t("available_interactions")} icon={LayersOutlinedIcon}>
                            <div className={"flex gap-[10px] px-[10px] py-[20px]"}>
                                {myInteractionsMapped}
                            </div>
                        </ActorPresetEditorField>
                    </div>
                </div>
            }
        </div>
    </div>

}