import React from "react";
import PiBooleanProperty from "../../components/PiProperties/PiBooleanProperty";
import PiNumberProperty from "../../components/PiProperties/PiNumberProperty";
import { InteractionProperty } from "../../models/InteractionProperty";
export default function InteractionPropertyView(props: { interactionProperty: InteractionProperty; onChange: (interactionProperty: InteractionProperty) => void }) {
  const interactionProperty = props.interactionProperty;
  const preview = () => {
    if (interactionProperty.type === "boolean") {
      return <PiBooleanProperty interactionProperty={interactionProperty} onChange={props.onChange} />;
    } else if (interactionProperty.type === "number") {
      return <PiNumberProperty interactionProperty={interactionProperty} onChange={props.onChange} />;
    } else if (interactionProperty.type === "text") {
      return <div>string</div>;
    } else {
      return <div>unknown</div>;
    }
  };
  return <>{preview()}</>;
}
