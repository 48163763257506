import {useDispatch, useSelector} from "react-redux";
import {addMostRecentInteraction, selectSelectedInteractionDefinition, selectSelectedInteractionPropStateId, setSelectedInteractionDefinition} from "../app/slices/InteractionPickerSlice";
import {InteractionType} from "../models/interactions/Interaction";
import {useAddInteraction} from "../features/training_timeline/AddInteractionHook";
import {addInteractionToSelectedTake} from "../app/slices/TrainingSlice";
import usePropHelper from "./PropHelper";
import {selectUsedProps} from "../app/slices/PropLibrarySlice";

export default function useInteractionPicker() {
    const {createInteraction, createPropInteraction} = useAddInteraction();
    const dispatch = useDispatch();
    const interactionDef = useSelector(selectSelectedInteractionDefinition);
    const selectedPropId = useSelector(selectSelectedInteractionPropStateId);
    
    const {getUsedPropByInstanceId} = usePropHelper();

    const addNewInteraction = () => {
        if (interactionDef.type === InteractionType.DynamicInteraction) {
            // get prop that is selected in the interaction picker
            const propDto = getUsedPropByInstanceId(selectedPropId);
            if (!propDto || !propDto.metadata || !propDto.metadata.dynamicInteractions) return;

            // get the matching dynamic interaction
            const dynInteraction = propDto.metadata.dynamicInteractions.find((interaction) => interaction.title === interactionDef.name);
            if (!dynInteraction) return;

            // create the dynamic interaction
            const newInteraction = createPropInteraction(dynInteraction, selectedPropId);

            // add the interaction to the selected take
            dispatch(addInteractionToSelectedTake(newInteraction));
        } else {
            // 1. create new interaction
            const interaction = createInteraction(interactionDef.type);
            // 2. add interaction to the selected take
            dispatch(addInteractionToSelectedTake(interaction));
            // 3. add the interaction to most recent
            dispatch(addMostRecentInteraction(interactionDef));
        }
    }

    return {addNewInteraction}
}