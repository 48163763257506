import MovieFilterOutlinedIcon from "@mui/icons-material/MovieFilterOutlined";
import { Button } from "@mui/material";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addInteractionCopy, addNewTake, addTakeCopy, selectSelectedElement, selectSelectedElementIsInteraction, selectSelectedElementIsTake, selectSelectedTake3D } from "../../app/slices/TrainingSlice";
import useShortcut, { ShortCuts } from "../../hooks/UseShortcutsHook";
import Interaction from "../../models/interactions/Interaction";
import Take3D from "../../models/Take3D";
import { useCopyInteraction } from "../training_timeline/CopyInteractionHook";
import "../training_timeline/TrainingTimeline.css";
import "./Timeline3D.css";
import Timeline3DTakesList from "./Timeline3DTakesList";
import PiButton from "../../components/PiButton";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";

export default function Timeline3D() {
  const dispatch = useDispatch();
  const scrollerRef = useRef<HTMLDivElement>(null);
  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const isTakeSelected: Boolean = useSelector(selectSelectedElementIsTake);
  const isSelectedElementInteraction: Boolean = useSelector(selectSelectedElementIsInteraction);
  const selectedTake: Take3D | undefined = useSelector(selectSelectedTake3D);
  const selectedElement = useSelector(selectSelectedElement);
  const { getInteractionCopy } = useCopyInteraction();

  const onAddTakeClick = async () => {
    dispatch(addNewTake({}));

    await delay(100);

    // scroll to bottom of scroller
    if (scrollerRef.current) scrollerRef.current.scrollTop = scrollerRef.current?.scrollHeight;
  };

  useShortcut(ShortCuts.Copy, () => {
    if (isSelectedElementInteraction) {
      navigator.clipboard.writeText(JSON.stringify(selectedElement));
    }
    else if (isTakeSelected) {
      navigator.clipboard.writeText(JSON.stringify(selectedTake));
    }
  });

  useShortcut(ShortCuts.Paste, async () => {
    const text = await navigator.clipboard.readText();

    //Check if string is parseable JSON
    try {
      var pastedObject = JSON.parse(text);
    }
    catch (error) {
      console.log('Not in JSON format');
      return;
    }

    if (isValidInteraction(pastedObject)) {
      dispatch(addInteractionCopy(getInteractionCopy(pastedObject)));
    }
    else if (isValidTake3D(pastedObject)) {
      dispatch(addTakeCopy(pastedObject));
    }
  });

  const list = (
    <div className="trainingtimeline-content-scroller pit-creator-scrollbar" ref={scrollerRef}>
      <Timeline3DTakesList />
    </div>
  );
 
  const addTakeButton = (
      <div className="px-[18px]">
        <PiButton variant={"piBlue"} onClick={onAddTakeClick}>
          <div className={"h-full flex justify-center mx-auto justify-items-center"}>
            <div className={"flex gap-[8px] my-auto text-[24px]"}>
              <MovieFilterOutlinedIcon className={""} fontSize={"inherit"}/>
              <div className={"text-[14px] my-auto"}>Add Take</div>
            </div>
          </div>
        </PiButton>
      </div>
  );
  
  return (
    <div className="h-full flex flex-col gap-y-[10px]">
      <Timeline3DTakesList />
      {addTakeButton}
    </div>
  );
}

function isValidTake3D(take3D: Take3D) {
  return take3D &&
    typeof take3D.id === 'string' &&
    typeof take3D.actors === 'object' &&
    typeof take3D.interactions === 'object' &&
    typeof take3D.isExpanded === 'boolean' &&
    typeof take3D.mainCamera === 'object' &&
    typeof take3D.name === 'string' &&
    typeof take3D.props === 'object'
}

function isValidInteraction(interaction: Interaction) {
  return interaction &&
    typeof interaction.type === "string" &&
    typeof interaction.id === "string" &&
    typeof interaction.name === "string"
}
