import {useSelector} from "react-redux";
import {selectAllPropPresets} from "../../app/slices/TrainingSlice";
import PropPresetPopoverCard from "./PropPresetPopoverCard";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import React from "react";
import classNames from "classnames";

export default function PropPresetPopover(props: { canDelete: boolean, onClick: (instanceId: string, propId: string) => void, onDelete: () => void }) {
    const {canDelete, onClick, onDelete} = {...props};
    const allProps = useSelector(selectAllPropPresets);

    const allPropsMapped = allProps.map((prop) => {
        return <PropPresetPopoverCard key={prop.id} prop={prop} onClick={() => onClick(prop.id, prop.propId)}/>
    });


    const deleteButton = (
        <div className="border-t-2 border-interaction-primary-washed">
            <div className={classNames({
                "h-[42px] flex gap-[10px] items-center text-[14px] m-2 rounded-[5px] cursor-pointer": true,
                "text-interaction-disabled": !canDelete,
                "text-interaction-secondary hover:bg-interaction-text-hoverBackground": canDelete,
            })} onClick={onDelete}>
                <DeleteOutlineOutlinedIcon className={"text-[24px]"} color="inherit"/>
                <span>Delete</span>
            </div>
        </div>
    );


    return <div className={"flex flex-col gap-[10px]"}>{allPropsMapped}{deleteButton}</div>
}