import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedTake, updateSelectedTake} from "../../app/slices/TrainingSlice";
import PiCardContent from "../../components/PiCardContent";
import PiDialog, {DialogProps} from "../../components/PiDialog";
import PiInputfield from "../../components/PiInputField";
import PiSelectAssetButton from "../../components/PiSelectAssetButton";
import PiSwitch from "../../components/PiSwitch";
import {AssetDetailsDTO, AssetKey} from "../../dto/AssetDTO";
import Take360 from "../../models/Take360";
import "./AddBubbleDialog.css";
import {OnboardingService} from "../onboarding/OnboardingService";
import {OnboardingContext} from "../onboarding/OnboardingContext";
import useDelay from "../../hooks/Delay.hook";

export default function AddBubbleDialog(props: DialogProps) {
    const {open, onClose} = {...props};
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const selectedTake = useSelector(selectSelectedTake);
    const {delay} = useDelay();

    // onboarding on add first bubble
    const {state, setState} = useContext(OnboardingContext);

    const onChangeName = (newName: string) => {
        var updatedTake: Take360 = {...selectedTake!};
        updatedTake.name = newName;
        dispatch(updateSelectedTake(updatedTake));
    };

    const onChangeAsset = (asset: AssetDetailsDTO) => {
        var updatedTake: Take360 = {...selectedTake!};
        updatedTake.assetId = asset.assetId;
        dispatch(updateSelectedTake(updatedTake));
    };

    const onToggleAutomaticTransition = (checked: boolean) => {
        var updatedTake: Take360 = {...selectedTake!};
        updatedTake.automaticTransition = checked;
        dispatch(updateSelectedTake(updatedTake));
    };
    const onToggleIs360Image = (checked: boolean) => {
        var updatedTake: Take360 = {...selectedTake!};
        updatedTake.is360Image = checked;
        updatedTake.display360 = false;
        dispatch(updateSelectedTake(updatedTake));
    };

    const onSubmit = async () => {
        onClose();
        await delay(200);
        const hasVisited = OnboardingService.HasVisited("addFirstBubble");
        if (!hasVisited) {
            await delay(200);
            console.log("starting add bubble onboarding");
            setState((prevState) => ({
                ...prevState,
                run: true,
                onboardingActive: true,
                context: "addFirstBubble",
            }));

            OnboardingService.SetVisited("addFirstBubble");
        }
    }

    const body = (
        <PiCardContent titleL={t("add_bubble_dialog.add")} titleR={t("edit_bubble_dialog.bubble")} titleVariant="blue" submitText={t("add_bubble_dialog.apply")} onSubmit={onSubmit}>
            <div className="addbubble-dialog-body">
                <PiSelectAssetButton label={t("edit_bubble_dialog.bubble")} text={t("change_bubble")} assetType={AssetKey.Image} onAssetSelectCallback={onChangeAsset}/>
                <PiInputfield label={t("edit_bubble_dialog.name")} placeholder={t("edit_bubble_dialog.name")} onChange={onChangeName} value={selectedTake?.name}/>
                <div className="addbubble-dialog-switches">
                    <PiSwitch label={t("edit_bubble_dialog.automatic_transition")} checked={selectedTake?.automaticTransition ?? false} onChange={onToggleAutomaticTransition}/>
                    <PiSwitch label={t("edit_bubble_dialog.360_image")} checked={selectedTake?.is360Image ?? false} onChange={onToggleIs360Image}/>
                </div>
            </div>
        </PiCardContent>
    );

    return <PiDialog open={open} onClose={onClose} width="450px" height="544px" header={<div/>} body={body}/>;
}
