import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import { Button } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addInteractionCopy,
  addTakeCopy,
  selectIsPlaying,
  selectSelectedElement,
  selectSelectedElementIsInteraction,
  selectSelectedElementIsTake,
  selectSelectedTake,
  selectTakes,
  setFilter,
} from "../../app/slices/TrainingSlice";
import PiInputfield from "../../components/PiInputField";
import { AssetDetailsDTO, AssetKey } from "../../dto/AssetDTO";
import useShortcut, { ShortCuts } from "../../hooks/UseShortcutsHook";
import Interaction from "../../models/interactions/Interaction";
import Take360 from "../../models/Take360";
import FileExplorerDialog from "../file_explorer/FileExplorerDialog";
import AddBubbleDialog from "../training_preview/AddBubbleDialog";
import { useCopyInteraction } from "./CopyInteractionHook";
import { useAddNewBubble } from "./SelectNewBubbleHook";
import TrainingTakeList from "./TrainingTakeList";
import "./TrainingTimeline360Content.css";

export default function TrainingTimeline360Content() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectImageAsset, isDialogOpen, setIsDialogOpen] = useAddNewBubble();
  const isPlaying = useSelector(selectIsPlaying);
  const takesCount = useSelector(selectTakes).length;
  const scrollerRef = useRef<HTMLDivElement>(null);

  const isTakeSelected: Boolean = useSelector(selectSelectedElementIsTake);
  const isSelectedElementInteraction: Boolean = useSelector(
    selectSelectedElementIsInteraction
  );
  const selectedTake: Take360 | undefined = useSelector(selectSelectedTake);
  const selectedElement = useSelector(selectSelectedElement);
  const { getInteractionCopy } = useCopyInteraction();

  const [isAddBubbleDialogOpen, setIsAddBubbleDialogOpen] =
    useState<boolean>(false);
  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  const onAddBubble = async (asset: AssetDetailsDTO) => {
    selectImageAsset(asset);

    setIsDialogOpen(false);
    setIsAddBubbleDialogOpen(true);
    await delay(100);

    // scroll to bottom of scroller
    if (scrollerRef.current)
      scrollerRef.current.scrollTop = scrollerRef.current?.scrollHeight;
  };

  useShortcut(ShortCuts.Copy, () => {
    if (isSelectedElementInteraction) {
      navigator.clipboard.writeText(JSON.stringify(selectedElement));
    } else if (isTakeSelected) {
      navigator.clipboard.writeText(JSON.stringify(selectedTake));
    }
  });

  useShortcut(ShortCuts.Paste, async () => {
    const text = await navigator.clipboard.readText();

    //Check if string is parseable JSON
    try {
      var pastedObject = JSON.parse(text);
    } catch (error) {
      console.log("Not in JSON format");
      return;
    }

    if (isValidInteraction(pastedObject)) {
      dispatch(addInteractionCopy(getInteractionCopy(pastedObject)));
    } else if (isValidTake360(pastedObject)) {
      dispatch(addTakeCopy(pastedObject));
    }
  });

  const searchField = (
    <div className="trainingtimeline-search">
      <PiInputfield
        label=""
        placeholder={t("search")}
        onChange={(newString) => {
          dispatch(setFilter(newString));
        }}
        disabled={takesCount === 0}
        showSearchIcon
      />
    </div>
  );

  const takeList = (
    <div
      className="trainingtimeline-content-scroller pit-creator-scrollbar py-[8px] px-[8px]"
      ref={scrollerRef}
    >
      <TrainingTakeList />
    </div>
  );

  const addBubbleButton = (
    <div className="trainingtimeline-add-bubble" id={"add-bubble-btn"}>
      <Button variant="piBlue" onClick={() => setIsDialogOpen(true)}>
        <div className="trainingtimeline-add-bubble-content">
          <LibraryAddOutlinedIcon />
          <span>{t("add_bubble")}</span>
        </div>
      </Button>
    </div>
  );

  return (
    <div className="trainingtimeline-root">
      <div className="trainingtimeline-content px-[12px]">
        {searchField}
        {takeList}
        {addBubbleButton}
      </div>

      <FileExplorerDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        type={AssetKey.Image}
        onSelect={onAddBubble}
      />
      <AddBubbleDialog
        onClose={() => setIsAddBubbleDialogOpen(false)}
        open={isAddBubbleDialogOpen}
      />
    </div>
  );
}

function isValidTake360(take360: Take360) {
  return (
    take360 &&
    typeof take360.id === "string" &&
    typeof take360.interactions === "object" &&
    typeof take360.isExpanded === "boolean" &&
    typeof take360.name === "string" &&
    typeof take360.automaticTransition === "boolean" &&
    typeof take360.display360 === "boolean"
  );
}

function isValidInteraction(interaction: Interaction) {
  return (
    interaction &&
    typeof interaction.type === "string" &&
    typeof interaction.id === "string" &&
    typeof interaction.name === "string"
  );
}
