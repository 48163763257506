import { useSelector } from "react-redux";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import ConversationPreviewBody from "./ConversationPreviewBody";
import { PiAvatarProfileGroup } from "./PiAvatarProfileGroup";
import { selectActors, selectSelectedElement } from "../../../app/slices/TrainingSlice";
import { IConversation } from "../../../models/interactions/Conversation";
import { Actor } from "../../../models/Actor";
import { useTranslation } from "react-i18next";

export default function ConversationPreview() {
  //Only use actors present in the conversation preview
  const allActors: Actor[] = useSelector(selectActors);
  const conversationActorIds: string[] = [...new Set(((useSelector(selectSelectedElement) as IConversation).messages).map(message => message.npcId))];
  const conversationActors: Actor[] = allActors.filter(actor => conversationActorIds.includes(actor.id));
  const { t } = useTranslation();

  const title = (
    <div className="flex justify-between w-full">
      <div className="not-italic font-semibold leading-normal text-32 text-interaction-primary ">
        {t("conversation")}
      </div>
      <PiAvatarProfileGroup actors={conversationActors} maxActorsShown={3} />
    </div>);
  const body = <ConversationPreviewBody />;

  return <PiPopupWindowPreview title={title} body={body} hideBottom={true} />;
}