import React from "react";
import "./QuizPromptPreview.css";
import PiInteractionTitlePreview from "./components/PiInteractionTitlePreview";

interface QuizPromptProps {
    onChange: (e: string) => void;
    value: string;
}

export default function QuizPromptPreview(props: QuizPromptProps) {
    const {onChange, value} = {...props};

    return (
        <div className="quizpromptpreview-root">
            <PiInteractionTitlePreview placeholder="Quiz Title" onChange={onChange} value={value} showQuizIcon/>
        </div>
    );
}
