import {useDispatch, useSelector} from "react-redux";
import {DynamicInteractionPropertyType, IDynamicInteraction, IDynamicInteractionProperty} from "../../models/interactions/DynamicInteraction";
import {selectSelectedElement, updateSelectedInteraction} from "../../app/slices/TrainingSlice";
import MessageBubbleActorDropdown from "../previews/message_bubble/MessageBubbleActorDropdown";

export default function DynamicInteractionPreviewBody() {
    const interaction = useSelector(selectSelectedElement) as IDynamicInteraction;
    const dispatch = useDispatch();

    const onEditField = (id: string, field: keyof IDynamicInteractionProperty, value: any) => {
        if (!interaction || !interaction.dynamicInteractionProperties) return;

        const target = interaction.dynamicInteractionProperties.find((prop) => prop.id === id);
        if (!target) return;

        const index = interaction.dynamicInteractionProperties.indexOf(target);
        const copy = {...target, [field]: value};

        const copiedArray = [...interaction.dynamicInteractionProperties];
        copiedArray[index] = copy;

        dispatch(updateSelectedInteraction({...interaction, dynamicInteractionProperties: copiedArray} as IDynamicInteraction));
    }

    // TODO: generate props

    const props = interaction.dynamicInteractionProperties.map((prop) => {

        if (prop.type === DynamicInteractionPropertyType.actorDropdown)
            return <MessageBubbleActorDropdown key={prop.id} label={prop.name} selectedTargetActorId={prop.value} onClick={(actorId) => {
                onEditField(prop.id, "value", actorId)
            }}/>;

    });


    return <div className={"w-full h-full"}>
        {props}
    </div>
}