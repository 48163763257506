import CheckIcon from "@mui/icons-material/Check";
import React from "react";
import { useTranslation } from "react-i18next";
import "./PiBasicGraphicsQuizAnswerFooter.css";
import { PiQuizAnswerProps } from "./PiBasicQuizAnswer";
export default function PiBasicGraphicsQuizAnswerFooter(props: PiQuizAnswerProps) {
  const { t } = useTranslation();

  const onCheckboxClick = () => {
    var toggle = !props.answer.correct;
    props.handleAnswerChange(props.answer.id, "checked", toggle);
  };

  return (
    <div className="pibasicgraphicsquizanswerfooter-root">
      <div className="pibasicgraphicsquizanswerfooter-checkbox" onClick={onCheckboxClick}>
        {props.answer.correct && <CheckIcon fontSize="inherit" />}
      </div>
      <div className="pibasicgraphicsquizanswerfooter-textfield">
        <input placeholder={t("answer_placeholder") ?? "Q A"} value={props.getValue(props.answer.text)} onChange={(e) => props.changeValue(props.answer.text, e.target.value)} />
      </div>
    </div>
  );
}
