import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PropMetadata} from "../../dto/PropMetadata";
import {RootState} from "../Store";
import {DynamicInteractionPropertyType} from "../../models/interactions/DynamicInteraction";
import {TranslatedPropCategoryDTO} from "../../dto/TranslatedPropCategoryDTO";
import {TranslatedPropDTO} from "../../dto/TranslatedPropDTO";
import {PropInteractionDTO} from "../../dto/PropInteractionDTO";
import {TranslatedPropContentDTO} from "../../dto/TranslatedPropContentDTO";
import {UsedProp} from "../../models/UsedProp";
import {AssetDetailsDTO} from "../../dto/AssetDTO";

// const extinguisher: PropMetadata = {
//     name: "Fire Extinguisher (Foam)",
//     id: "1d8aa69e-41d1-467c-9997-c8d2ce4a0a10",
//     thumbnailUrl: "/props/extinguisher.png",
//     prefab_name: "Creator_ExtinguisherFoam",
//     dynamicInteractions: [
//         {
//             title: "Not real",
//             thumbnailUrl: "test",
//             properties: [],
//             description: "not_real",
//             interactionTag: "not_real"
//         }
//     ]
// }
//
// const firecontainer: PropMetadata = {
//     name: "Fire Container",
//     id: "47bb7fd6-2a32-497d-ba98-234d86e42b4e",
//     thumbnailUrl: "/props/fire_container.png",
//     prefab_name: "FireContainer",
//     dynamicInteractions: [
//         {
//             title: "Extinguish Fire",
//             interactionTag: "creator_extinguish_fire",
//             description: "something for the AI",
//             thumbnailUrl: "coming_soon.png",
//             properties: [
//                 {
//                     "id": "target",
//                     "title": "Actor",
//                     "description": "The actor that will extinguish the fire",
//                     type: DynamicInteractionPropertyType.actorDropdown,
//                     "requiredTag": "extinguisher",
//                     value: "",
//                 },
//                 {
//                     "id": "distance",
//                     "title": "Distance",
//                     "description": "How far away from the fire the player needs to stand to safely extinguish the fire",
//                     "type": DynamicInteractionPropertyType.stringDropdown,
//                     "values": ["1m", "2m", "3m"],
//                     value: "",
//                 },
//                 {
//                     "id": "duration",
//                     "title": "Extinguish Duration",
//                     "description": "dont care",
//                     "type": DynamicInteractionPropertyType.number,
//                     value: ""
//                 }
//             ]
//         }
//     ]
// }

interface PropLibraryState {
    isInitialized: boolean;
    selectedPropId: string;
    categories: TranslatedPropCategoryDTO[];
    interactions: PropInteractionDTO[];
    filter: string;
    filteredCategory: string;
    pagedProps: TranslatedPropDTO[];
    selectedPropContent: TranslatedPropContentDTO | null;
    selectedPropMetadata: PropMetadata | null;
    usedProps: Record<string, UsedProp>; // [propId: string]: UsedProp;
}

const initialPropLibraryState: PropLibraryState = {
    isInitialized: false,
    selectedPropId: "",
    categories: [],
    interactions: [],
    filter: "",
    filteredCategory: "",
    pagedProps: [],
    selectedPropContent: null,
    selectedPropMetadata: null,
    usedProps: {}
};

export const propLibrarySlice = createSlice({
    name: "propLibrary",
    initialState: initialPropLibraryState,
    reducers: {
        initializePropLibrary: (state, action: PayloadAction<{ totalCount: number, firstPage: TranslatedPropDTO[] }>) => {
            state.isInitialized = true;
            state.pagedProps = new Array(action.payload.totalCount).fill(null);

            for (let i = 0; i < action.payload.firstPage.length; i++) {
                state.pagedProps[i] = action.payload.firstPage[i];
            }
        },
        addPagedProps: (state, action: PayloadAction<{pageNumber: number; assets: TranslatedPropDTO[] }>) => {
            var si = action.payload.pageNumber * 10;
            if(si > state.pagedProps.length) return;
            
            for(var i = 0; i < action.payload.assets.length; i++) {
                state.pagedProps[si + i] = action.payload.assets[i];
            }
        },
        setSelectedProp: (state, action: PayloadAction<string>) => {
            state.selectedPropId = action.payload;
        },
        setPropCategories: (state, action: PayloadAction<TranslatedPropCategoryDTO[]>) => {
            state.categories = action.payload;
        },
        setPropInteractions: (state, action: PayloadAction<PropInteractionDTO[]>) => {
            state.interactions = action.payload;
        },
        setPropFilter: (state, action: PayloadAction<string>) => {
            state.filter = action.payload;
        },
        setPropCategoryFilter: (state, action: PayloadAction<string>) => {
            state.filteredCategory = action.payload;
        },
        setSelectedPropContent: (state, action: PayloadAction<{ content: TranslatedPropContentDTO, metadata: PropMetadata }>) => {
            state.selectedPropContent = action.payload.content;
            state.selectedPropMetadata = action.payload.metadata;
        },
        addSelectedPropAsUsedProp: (state) => {
            if (!state.selectedPropContent || !state.selectedPropMetadata) return;

            state.usedProps[state.selectedPropContent.id] = {
                propContent: state.selectedPropContent,
                metadata: state.selectedPropMetadata
            };
        },
        addUsedProps: (state, action: PayloadAction<UsedProp[]>) => {
            for (let i = 0; i < action.payload.length; i++) {
                state.usedProps[action.payload[i].propContent.id] = action.payload[i];
            }
        }
    },
});

export const {
    initializePropLibrary,
    addPagedProps,
    setSelectedProp,
    setPropCategories,
    setPropInteractions,
    setPropFilter,
    setPropCategoryFilter,
    setSelectedPropContent,
    addSelectedPropAsUsedProp,
    addUsedProps
} = propLibrarySlice.actions;

export const selectIsPropSliceInitialized = (state: RootState) => state.propLibrary.isInitialized;
export const selectPropCategories = (state: RootState) => state.propLibrary.categories;
export const selectPropInteractions = (state: RootState) => state.propLibrary.interactions;
export const selectPropFilter = (state: RootState) => state.propLibrary.filter;
export const selectFilteredCategory = (state: RootState) => state.propLibrary.filteredCategory;
export const selectSelectedPropContent = (state: RootState) => state.propLibrary.selectedPropContent;
export const selectProps = (state: RootState) => state.propLibrary.pagedProps;
export const selectUsedProps = (state: RootState) => state.propLibrary.usedProps;

export const selectSelectedProp = (state: RootState) => state.propLibrary.pagedProps.find(prop => prop.id === state.propLibrary.selectedPropId);
export const selectSelectedPropMetadata = (state: RootState) => state.propLibrary.selectedPropMetadata;
export const selectSelectedPropId = (state: RootState) => state.propLibrary.selectedPropId;
export const selectPropById = (state: RootState, propId: string) => {
    //return state.propLibrary.props.find(prop => prop.id === propId);
    return state.propLibrary.pagedProps.find(prop => prop.id === propId);
}

export default propLibrarySlice.reducer;