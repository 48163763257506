import PropertiesColumnHeader from "../properties_column/PropertiesColumnHeader";
import PropertiesColumnBody from "../properties_column/PropertiesColumnBody";
import TakePropertiesActorList from "./TakePropertiesActorList";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedTake, updateSelectedTake} from "../../app/slices/TrainingSlice";
import PiInputfield from "../../components/PiInputField";
import PiCollapsibleSettings from "../../components/PiCollapsibleSettings";
import MovieCreationOutlinedIcon from "@mui/icons-material/MovieCreationOutlined";
import Take from "../../models/Take";
import {useTranslation} from "react-i18next";

export default function Take360PropertiesColumn() {
    const take = useSelector(selectSelectedTake);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    
    const onChangeTakeName = (newName: string) => {
        dispatch(updateSelectedTake({...take, name: newName} as Take));
    }

    const takeName = <>
        <PiInputfield label={t("take_name")} value={take?.name ?? ""} placeholder={"Take Name"} onChange={onChangeTakeName}/>
    </>;

    const takeTab = <PiCollapsibleSettings text={"Take"} icon={<MovieCreationOutlinedIcon color={"inherit"} fontSize={"inherit"}/>} paddingX paddingY>
        {takeName}
    </PiCollapsibleSettings>;


    return <>
        <PropertiesColumnHeader text={take?.name ?? "Take"}/>
        <PropertiesColumnBody>
            {takeTab}
        </PropertiesColumnBody>
    </>
}