import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CameraPosition} from "../../app/slices/EnvironmentSlice";
import {selectSelectedElementType, selectSelectedTake3D, setSelectedInteraction, setSelectedTake, updateSelectedTake} from "../../app/slices/TrainingSlice";
import Take3D from "../../models/Take3D";
import InteractionPointPreview, {CameraPointStrategy, SelectedCameraPointStrategy} from "./InteractionPointPreview";
import {Viewport3DLayerProps} from "./Viewport3DEnvironmentMetadata";
import {useMapEvents} from "react-leaflet";

export default function Viewport3DCameraLayer(props: Viewport3DLayerProps) {
    const dispatch = useDispatch();
    const {currentTakeLocation, isCameraSelected} = {...props};
    const currentTake = useSelector(selectSelectedTake3D) as Take3D;
    const currentCamId = useMemo(() => {
        return currentTake?.mainCamera.cameraId;
    }, [currentTake]);
    const useDefaultCam = useMemo(() => {
        return currentTake?.properties?.find((prop) => prop.name === "Use Default Camera")?.value === true;
    }, [currentTake]);

    const selectedElementType = useSelector(selectSelectedElementType);

    // testing the hook
    const map = useMapEvents({
        click: (e) => {
            // if selected element is cam, deselect ! -> is onBlur basically
            if (selectedElementType === "camera")
                dispatch(setSelectedTake(currentTake.id));
        }
    })

    const onCamPointClick = (cam: CameraPosition) => {
        if (!currentTake) return;
        if (cam.id === currentCamId) return;

        const updatedTake: Take3D = {...currentTake!, mainCamera: {...currentTake!.mainCamera, cameraId: cam.id}};
        dispatch(updateSelectedTake(updatedTake));
    };

    const onSelectedCamClick = () => {
        dispatch(setSelectedInteraction(currentTake.mainCamera.id));
    }

    // TODO: cameras
    const cams = currentTakeLocation?.camera_positions.map((cam, index) => {
        return (
            <InteractionPointPreview
                strategy={currentCamId === cam.id ? SelectedCameraPointStrategy : CameraPointStrategy}
                x={cam.position.x}
                y={cam.position.y}
                key={index}
                onClick={() => onCamPointClick(cam)}
            />
        );
    });

    const selectedCam = useMemo(() => {
        const camPos = currentTakeLocation?.camera_positions.find((cam) => cam.id === currentCamId);
        
        if(camPos) return <InteractionPointPreview strategy={SelectedCameraPointStrategy} x={camPos.position.x} y={camPos.position.y} onClick={onSelectedCamClick}/>;
        else return <></>;
        
    }, [currentTakeLocation, currentTake, isCameraSelected]);

    return (
        <>
            {isCameraSelected && cams}
            {!isCameraSelected && selectedCam}
        </>
    );
}
