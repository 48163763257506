import { useEffect } from "react";
import { registerInteraction } from "../features/interaction_factory/InteractionFactory";
import { InteractionType } from "../models/interactions/Interaction";
import { audioPopupInteraction } from "../models/interactions/AudioPopup";
import { ambientAudioInteraction } from "../models/interactions/AmbientAudio";
import { clickRegionInteraction } from "../models/interactions/ClickRegion";
import { clickRegionMultiSelectQuizInteraction } from "../models/interactions/ClickRegionMultiSelectQuiz";
import { clickRegionOrderQuizInteraction } from "../models/interactions/ClickRegionOrderQuiz";
import { connectGraphicsQuizInteraction } from "../models/interactions/ConnectGraphicsQuiz";
import { connectQuizInteraction } from "../models/interactions/ConnectQuiz";
import { conversationInteraction } from "../models/interactions/Conversation";
import { dialogQuizInteraction } from "../models/interactions/DialogQuiz";
import { dynamicInteraction } from "../models/interactions/DynamicInteraction";
import { emotionBubbleInteraction } from "../models/interactions/EmotionBubble";
import { grabInteraction } from "../models/interactions/Grab";
import { imagePopupInteraction } from "../models/interactions/ImagePopup";
import { lisaMessageInteraction } from "../models/interactions/LisaMessage";
import { markerInteraction } from "../models/interactions/Marker";
import { messageBubbleInteraction } from "../models/interactions/MessageBubble";
import { messagePopupInteraction } from "../models/interactions/MessagePopup";
import { multipleChoiceGraphicsQuizInteraction } from "../models/interactions/MultipleChoiceGraphicsQuiz";
import { multipleChoiceQuizInteraction } from "../models/interactions/MultipleChoiceQuiz";
import { multiSelectGraphicsQuizInteraction } from "../models/interactions/MultiSelectGraphicsQuiz";
import { multiSelectQuizInteraction } from "../models/interactions/MultiselectQuiz";
import { createOrderGraphicsQuiz } from "../models/interactions/OrderGraphicsQuiz";
import { orderQuizInteraction } from "../models/interactions/OrderQuiz";
import { pdfPopupInteraction } from "../models/interactions/PdfPopup";
import { photoRangeQuizInteraction } from "../models/interactions/PhotoRangeQuiz";
import { scrabbleQuizInteraction } from "../models/interactions/ScrabbleQuiz";
import { videoPopupInteraction } from "../models/interactions/VideoPopup";
import { wordQuizInteraction } from "../models/interactions/WordQuiz";
import { wordSpinnerQuizInteraction } from "../models/interactions/WordSpinnerQuiz";
import TeleportMarkerInteraction from "../models/interactions/TeleportMarker";

export default function useInteractionFactoryImports() {
  useEffect(() => {
    registerInteraction(
      InteractionType.AmbientAudio,
      ambientAudioInteraction()
    );
    registerInteraction(InteractionType.AudioPopup, audioPopupInteraction());
    registerInteraction(InteractionType.ClickRegion, clickRegionInteraction());
    registerInteraction(
      InteractionType.ClickRegionMultiSelectQuiz,
      clickRegionMultiSelectQuizInteraction()
    );
    registerInteraction(
      InteractionType.ClickRegionOrderQuiz,
      clickRegionOrderQuizInteraction()
    );
    registerInteraction(
      InteractionType.ConnectGraphicsQuiz,
      connectGraphicsQuizInteraction()
    );
    registerInteraction(InteractionType.ConnectQuiz, connectQuizInteraction());
    registerInteraction(
      InteractionType.Conversation,
      conversationInteraction()
    );
    registerInteraction(InteractionType.DialogQuiz, dialogQuizInteraction());
    registerInteraction(
      InteractionType.DynamicInteraction,
      dynamicInteraction()
    );
    registerInteraction(
      InteractionType.EmotionBubble,
      emotionBubbleInteraction()
    );
    registerInteraction(InteractionType.Grab, grabInteraction());
    registerInteraction(InteractionType.ImagePopup, imagePopupInteraction());
    registerInteraction(InteractionType.LisaMessage, lisaMessageInteraction());
    registerInteraction(InteractionType.Marker, markerInteraction());
    registerInteraction(
      InteractionType.MessageBubble,
      messageBubbleInteraction()
    );
    registerInteraction(
      InteractionType.MessagePopup,
      messagePopupInteraction()
    );
    registerInteraction(
      InteractionType.MultipleChoiceGraphicsQuiz,
      multipleChoiceGraphicsQuizInteraction()
    );
    registerInteraction(
      InteractionType.MultipleChoiceQuiz,
      multipleChoiceQuizInteraction()
    );
    registerInteraction(
      InteractionType.MultiSelectGraphicsQuiz,
      multiSelectGraphicsQuizInteraction()
    );
    registerInteraction(
      InteractionType.MultiselectQuiz,
      multiSelectQuizInteraction()
    );
    registerInteraction(
      InteractionType.OrderGraphicsQuiz,
      createOrderGraphicsQuiz()
    );
    registerInteraction(InteractionType.OrderQuiz, orderQuizInteraction());
    registerInteraction(InteractionType.PdfPopup, pdfPopupInteraction());
    registerInteraction(
      InteractionType.PhotoRangeQuiz,
      photoRangeQuizInteraction()
    );
    registerInteraction(
      InteractionType.ScrabbleQuiz,
      scrabbleQuizInteraction()
    );
    registerInteraction(InteractionType.VideoPopup, videoPopupInteraction());
    registerInteraction(InteractionType.WordQuiz, wordQuizInteraction());
    registerInteraction(
      InteractionType.WordSpinnerQuiz,
      wordSpinnerQuizInteraction()
    );
    registerInteraction(
      InteractionType.TeleportMarker,
      TeleportMarkerInteraction()
    );
  }, []);

  return {};
}
