import classNames from "classnames";
import CheckIcon from "@mui/icons-material/Check";

type PiQuizCheckboxProps = {
    checked: boolean;
    onChange: (e: boolean) => void;
    square?: boolean;
}

export default function PiBasicQuizCheckboxV2(props: PiQuizCheckboxProps) {

    const {checked, onChange, square} = {...props};

    const onClick = () => onChange(!checked);

    return <div className={classNames({
        "w-[36px] h-[36px] flex box-border cursor-pointer": true,
        "rounded-full": !square,
        "rounded-[5px]": square,
        "bg-interaction-correct hover:bg-interaction-correct-hover": checked,
        "border-solid border-2 border-interaction-primary hover:bg-interaction-primary-washed": !checked,
    })}
                onClick={onClick}>
        {checked && <div className={"m-auto text-white"}>
            <CheckIcon/>
        </div>}
    </div>
}