import { v4 as uuidv4 } from "uuid";
import Interaction, { InteractionType } from "./Interaction";
import { GetMigratedProperties } from "../../features/project_migration/InteractionMigrationHelper";
import {
  InteractionProperty,
  InteractionPropertyType,
} from "../InteractionProperty";
import { requiredGlobalQuizProperties } from "../GlobalQuizProperty";
import { IAnswer, IFormQuiz } from "../Quiz";
import {
  InteractionFactoryEntity,
  registerInteraction,
} from "../../features/interaction_factory/InteractionFactory";
import WordSpinnerQuizPreview from "../../features/previews/word_spinner_quiz/WordSpinnerQuizPreview";

export interface IWordSpinnerQuiz extends IFormQuiz {
  formattedSentence: string;
}

export const requiredWordSpinnerProperties: InteractionProperty[] = [
  ...requiredGlobalQuizProperties,
];

export function CreateWordspinnerQuiz(keys: string[]): IWordSpinnerQuiz {
  return {
    id: uuidv4(),
    name: "Word Spinner Quiz",
    type: InteractionType.WordSpinnerQuiz,
    title: keys[0],
    formattedSentence: keys[1],
    answers: [],
    properties: requiredWordSpinnerProperties,
  };
}

export function CreateWordspinnerQuizCopy(
  titleKey: string,
  sentencePart1Key: string,
  sentencePart2Key: string,
  formattedSentenceKey: string,
  rawSentenceKey: string,
  correctIndex: number,
  answers: IAnswer[],
  properties: InteractionProperty[] | undefined
): IWordSpinnerQuiz {
  return {
    id: uuidv4(),
    name: "Word Spinner Quiz",
    type: InteractionType.WordSpinnerQuiz,
    title: titleKey,
    formattedSentence: formattedSentenceKey,
    answers: answers,
    properties: properties,
  };
}

export function MigrateWordspinnerQuiz(
  from: number,
  to: number,
  interaction: IWordSpinnerQuiz
): IWordSpinnerQuiz {
  var updatedInteraction: IWordSpinnerQuiz = { ...interaction };

  if (from < 3) {
    const oldInteraction: any = JSON.stringify(interaction);
    const newAnswer = oldInteraction["correctAnswer"] as IAnswer | undefined;

    if (newAnswer !== undefined) {
      updatedInteraction = {
        ...interaction,
        answers: [newAnswer, ...interaction.answers],
      };
    }
  }

  if (updatedInteraction.properties === undefined)
    return { ...updatedInteraction, properties: requiredWordSpinnerProperties };

  var newProperties: InteractionProperty[] = GetMigratedProperties(
    requiredWordSpinnerProperties,
    updatedInteraction.properties
  );
  console.log(
    "[WordSpinnerQuiz] migrated " +
      (newProperties.length - updatedInteraction.properties.length) +
      " properties"
  );

  return { ...updatedInteraction, properties: newProperties };
}

interface LegacyWordspinner {
  correctAnswer: IAnswer;
}

export const wordSpinnerQuizInteraction = (): InteractionFactoryEntity => {
  const component = <WordSpinnerQuizPreview />;

  const create = (getKeys: (amount: number) => string[]): IWordSpinnerQuiz => {
    const keys = getKeys(2);
    return {
      id: uuidv4(),
      name: "Word Spinner Quiz",
      type: InteractionType.WordSpinnerQuiz,
      title: keys[0],
      formattedSentence: keys[1],
      answers: [],
      properties: requiredWordSpinnerProperties,
    };
  };
  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IWordSpinnerQuiz => {
    var converted = interaction as IWordSpinnerQuiz;
    var updatedInteraction: IWordSpinnerQuiz = { ...converted };

    if (from < 4) {
      const oldInteraction: any = JSON.stringify(interaction);
      const legacy: LegacyWordspinner = JSON.parse(oldInteraction);
      const newAnswer = legacy.correctAnswer;

      if (newAnswer !== undefined) {
        updatedInteraction = {
          ...converted,
          answers: [newAnswer, ...converted.answers],
        };
      }
    }

    if (updatedInteraction.properties === undefined)
      return {
        ...updatedInteraction,
        properties: requiredWordSpinnerProperties,
      };

    var newProperties: InteractionProperty[] = GetMigratedProperties(
      requiredWordSpinnerProperties,
      updatedInteraction.properties
    );
    console.log(
      "[WordSpinnerQuiz] migrated " +
        (newProperties.length - updatedInteraction.properties.length) +
        " properties"
    );

    return { ...updatedInteraction, properties: newProperties };
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): IWordSpinnerQuiz => {
    const quiz = interaction as IWordSpinnerQuiz;
    const newKeys = getKeys(quiz.answers.length + 2);
    replaceKey(quiz.title, newKeys[newKeys.length - 1]);
    replaceKey(quiz.formattedSentence, newKeys[newKeys.length - 2]);

    return {
      id: uuidv4(),
      name: interaction.name,
      type: InteractionType.WordSpinnerQuiz,
      title: newKeys[newKeys.length - 1],
      formattedSentence: newKeys[newKeys.length - 2],
      answers: getAnswerArray(quiz.answers, newKeys),
      properties: quiz.properties,
    };
  };

  return {
    View: component,
    Create: create,
    Migrate: migrate,
    Copy: copy,
  };
};
