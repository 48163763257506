import { useState } from "react";
import { useDispatch } from "react-redux";
import { addNewTake } from "../../app/slices/TrainingSlice";
import { AssetDetailsDTO } from "../../dto/AssetDTO";

export const useAddNewBubble = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const selectImageAsset = (asset: AssetDetailsDTO) => {
    setIsDialogOpen(false);
    // add new bubble to training, assetid is id
    dispatch(addNewTake({ name: asset.name, assetId: asset.assetId }));
  };

  return [selectImageAsset, isDialogOpen, setIsDialogOpen] as const;
};
