import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedElement, updateSelectedInteractionProperty} from "../../app/slices/TrainingSlice";
import Interaction from "../../models/interactions/Interaction";
import {InteractionProperty} from "../../models/InteractionProperty";
import PropertiesColumnHeader from "../properties_column/PropertiesColumnHeader";
import "./InteractionPropertiesColumn.css";
import InteractionPropertyView from "./InteractionPropertyView";

export default function InteractionPropertiesColumn() {
    const interaction = useSelector(selectSelectedElement) as Interaction;
    const dispatch = useDispatch();

    useEffect(() => {
    }, [interaction]);

    const onChange = (interactionProperty: InteractionProperty) => {
        dispatch(updateSelectedInteractionProperty(interactionProperty));
    };

    const views = interaction?.properties?.map((property, index) => {
        return <InteractionPropertyView key={index} interactionProperty={property} onChange={onChange}/>;
    });

    return (
        <div className="interactionpropertiescontent-root">
            <PropertiesColumnHeader text={interaction?.name}/>
            <div className="px-[14px] py-[20px]">{views}</div>
        </div>
    );
}
  