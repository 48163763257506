import {useDispatch, useSelector} from "react-redux";
import {selectProps, selectSelectedPropId, setSelectedProp} from "../../app/slices/PropLibrarySlice";
import PropLibraryCard from "./PropLibraryCard";
import React, {CSSProperties, useEffect, useMemo, useRef, useState} from "react";
import {TranslatedPropDTO} from "../../dto/TranslatedPropDTO";
import UsePropLibrary from "./PropLibrary.hooks";
import {ColumnSizer, Grid} from "react-virtualized";
import {RenderedSection} from "react-virtualized/dist/es/Grid";
import usePropLibraryPagination from "./PropLibraryPagination.hooks";

export default function PropLibraryItems() {
    const allProps = useSelector(selectProps);
    const selectedPropId = useSelector(selectSelectedPropId);
    const dispatch = useDispatch();
    const {loadPropContent} = UsePropLibrary();
    const parentRef = useRef<HTMLDivElement>(null);
    const {onRerender} = usePropLibraryPagination();

    const [calculatedWidth, setCalculatedWidth] = useState<number>(0);
    const [amountOfColumns, setAmountOfColumns] = useState<number>(4);
    const [gridHeight, setGridHeight] = useState<number>(0);

    const rowCount = useMemo(() => {
        return Math.ceil(allProps.length / amountOfColumns)
    }, [allProps, amountOfColumns]);

    const onCardClick = (prop: TranslatedPropDTO) => {
        dispatch(setSelectedProp(prop.id));
        loadPropContent(prop.id);
    }

    useEffect(() => {
        onWindowResize();
        window.addEventListener("resize", onWindowResize);

        return () => window.removeEventListener("resize", onWindowResize);
    });

    const onWindowResize = () => {
        if (parentRef.current) {
            // manually calculate the width of the grid + calculate the amount of columns that fit on the screen
            const offset = 750 + 128;
            const newCalcWidth = window.innerWidth * 0.9 - offset;
            const newcols = newCalcWidth / 350;

            setCalculatedWidth(newCalcWidth);
            setAmountOfColumns(Math.round(newcols));
            setGridHeight(parentRef.current.clientHeight);
        }
    };


    const getCell = (index: number, key: string, style: CSSProperties) => {
        const prop = allProps[index];
        return <div key={key} style={style}>
            {index - 1 < allProps.length && prop !== undefined && <PropLibraryCard prop={prop} selected={prop.id === selectedPropId} onClick={() => onCardClick(prop)}/>}
        </div>
    }

    const cellRenderer = ({columnIndex, key, rowIndex, style}: { columnIndex: number; key: string; rowIndex: number; style: CSSProperties }) => {

        const index = amountOfColumns * rowIndex + columnIndex;
        return getCell(index, key, style);
    };

    function onSectionRenderer({
                                   columnOverscanStartIndex,
                                   columnOverscanStopIndex,
                                   columnStartIndex,
                                   columnStopIndex,
                                   rowOverscanStartIndex,
                                   rowOverscanStopIndex,
                                   rowStartIndex,
                                   rowStopIndex,
                               }: RenderedSection) {
        onRerender(amountOfColumns, rowStartIndex, rowStopIndex);
    }


    const grid = <ColumnSizer columnMaxWidth={1000} columnMinWidth={0} columnCount={amountOfColumns} width={calculatedWidth}>
        {({adjustedWidth, getColumnWidth, registerChild}) => (
            <Grid
                ref={registerChild}
                cellRenderer={cellRenderer}
                columnCount={amountOfColumns}
                columnWidth={getColumnWidth}
                height={gridHeight}
                rowCount={rowCount}
                rowHeight={264}
                width={adjustedWidth}
                autoWidth
                onSectionRendered={onSectionRenderer}
            />
        )}
    </ColumnSizer>

    return (
        <div ref={parentRef} className={"w-full h-full px-[24px]"}>
            {allProps.length > 0 && grid}
            {allProps.length === 0 && <div>No props found</div>}
        </div>
    );
}