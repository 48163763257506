import React from "react";
import { useSelector } from "react-redux";
import { selectIsPlaying } from "../../app/slices/TrainingSlice";
import { TrainingEditorStrategy } from "../training_preview/TrainingEditorStrategy";
import "./TrainingTimeline.css";

export default function TrainingTimeline(props: { strategy: TrainingEditorStrategy }) {
  const { strategy } = props;
  const isPlaying = useSelector(selectIsPlaying);
  const getRootClasses = () => {
    if (!isPlaying) return "trainingtimeline-root";
    else return "trainingtimeline-root trainingtimeline-root-disabled";
  };

  return (
    <div className={getRootClasses()}>
      <div className="trainingtimeline-content">{strategy.getTimeline()}</div>
    </div>
  );
}
