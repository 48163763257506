import {activitiesMapped, actorProps, ActorState, propsMapped} from "../../models/ActorState";
import PiDropdownV2 from "../../components/PiDropdownV2";
import {PiPopperMenuProps} from "../../components/PiPopper";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import PiCreatorDropdown from "../../components/PiCreatorDropdown";
import React from "react";

export default function ActorStatePropDropdown(props: {
    actorState: ActorState;
    onChange: (state: string) => void
}) {

    const {actorState, onChange} = {...props};

    const onClick = (index: number) => {
        onChange(actorProps[index]);
    }

    const selectedText = actorState.prop;
    const options: PiPopperMenuProps[] = actorProps.map(activity => {
        return {
            text: activity
        }
    });

    const dropdown = <PiDropdownV2 label={"Prop"} options={options} selectedText={selectedText} onClick={onClick} width={"264px"}/>

    const items = Array.from(propsMapped).map(([key, value]: [string, string], index) => {
        const image = value ? <img src={value} alt={key} className={"w-[36px] h-[36px] object-contain"}/> :
            <div className={"flex w-[36px] h-[36px]"}><PersonOffOutlinedIcon className={"my-auto"}/></div>
        const label = <div className={"my-auto text-creator-text text-[14px] font-medium"}>{key}</div>
        return <div className={"flex gap-[10px] py-[8px]"} onClick={() => onChange(key)}>
            {image}
            {label}</div>
    });

    const trigger = <div className={"flex gap-[10px] h-full w-full"}>
        <img src={propsMapped.get(actorState.prop)} alt={"/"} className={"my-auto w-[36px] h-[36px] object-contain"}/>
        <div className={"my-auto text-creator-text text-[14px] font-medium"}>{actorState.prop}</div>
    </div>;

    const newDropdown = <PiCreatorDropdown label={"Prop"} trigger={trigger} items={items as JSX.Element[]}/>

    return <>{newDropdown}</>
}