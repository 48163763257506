import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import StopIcon from "@mui/icons-material/Stop";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import { Button, IconButton } from "@mui/material";
import React, { useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage, selectLocalizedLanguages, setCurrentLanguage } from "../../app/slices/TrainingLocalizationSlice";
import { selectIsDirty, selectIsPlaying, selectTakes, selectTakes3D, selectTrainingName, setIsPlaying } from "../../app/slices/TrainingSlice";
import PiSplitButton, { PiSplitButtonData } from "../../components/PiSplitButton";
import { useTestProject } from "../../hooks/TestProjectHook";
import TrainingLocalizationDialog from "../trainingLocalization/TrainingLocalizationDialog";
import TrainingActionbarTitle from "./TrainingActionbarTitle";
import "./TrainingActionbar.css";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import TranslateIcon from "@mui/icons-material/Translate";
import { useTranslation } from "react-i18next";
import PiDropdown from "../../components/PiDropdown";
import TrainingActionbarSaveButton from "./TrainingActionbarSaveButton";

export default function TrainingActionbar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedTestButtonIndex, setSelectedTestButtonIndex] = useState<number>(0);
  const trainingName = useSelector(selectTrainingName);

  const localizedLanguages = useSelector(selectLocalizedLanguages);
  const selectedLanguage = useSelector(selectCurrentLanguage);
  const isPlaying = useSelector(selectIsPlaying);
  const takesCount = useSelector(selectTakes).length;
  const takes3DCount = useSelector(selectTakes3D).length;

  const isDirty = useSelector(selectIsDirty);
  const [testCurrentProject, testCurrentBubble] = useTestProject();

  const stopPlayingClicked = () => dispatch(setIsPlaying({ isPlaying: false, data: "" }));

  const onChangeSelectedTestButtonIndex = (newIndex: number) => setSelectedTestButtonIndex(newIndex);

  const langOptions = localizedLanguages.map((value, index) => {
    return <option key={index}>{value.full}</option>;
  });

  const onChangeLanguageDropdown = (newLang: string) => {
    dispatch(setCurrentLanguage(localizedLanguages.find((lang) => lang.full === newLang)!));
  };

  const undoRedo = (
    <div className="trainingactionbar-undoredo">
      <Button className="trainingactionbar-undoredo-button" variant="piBlueOutlined">
        <UndoOutlinedIcon />
        <span>Undo</span>
      </Button>
      <Button className="trainingactionbar-undoredo-button" variant="piBlueOutlined">
        <span>Redo</span>
        <RedoOutlinedIcon />
      </Button>
    </div>
  );

  const splitButtonDataPlay: PiSplitButtonData[] = [
    {
      text: t("current_project"),
      icon: <PlayArrowIcon />,
      onClick: testCurrentProject,
    },
    {
      text: t("current_bubble"),
      icon: <PlayArrowIcon />,
      onClick: testCurrentBubble,
    },
  ];

  const splitButtonDataStop: PiSplitButtonData[] = [
    {
      text: t("current_project"),
      icon: <StopIcon />,
      onClick: stopPlayingClicked,
    },
    { text: t("current_bubble"), icon: <StopIcon />, onClick: stopPlayingClicked },
  ];

  const testButton = (
    <div className="trainingactionbar-test-button">
      {!isPlaying ? (
        <PiSplitButton
          variant="piBlueOutlined"
          selectedIndex={selectedTestButtonIndex}
          onChangeSelectedIndex={onChangeSelectedTestButtonIndex}
          options={splitButtonDataPlay}
          disabled={takesCount === 0 && takes3DCount === 0}
        />
      ) : (
        <PiSplitButton variant="piRedOutlined" selectedIndex={selectedTestButtonIndex} onChangeSelectedIndex={onChangeSelectedTestButtonIndex} options={splitButtonDataStop} disableDropdown />
      )}
    </div>
  );

  const newDropdown = (
    <PiDropdown
      options={localizedLanguages.map((option) => {
        return {
          text: option.full,
          onClick: () => onChangeLanguageDropdown(option.full),
        };
      })}
      selectedText={selectedLanguage.full}
    />
  );

  const localizationButtons = (
    <div className="trainingactionbar-localization">
      <div className="trainingactionbar-localization-dropdown">{newDropdown}</div>
      <div className="trainingactionbar-localization-button">
        <IconButton color="inherit" onClick={() => setIsOpen(true)}>
          <TranslateIcon fontSize="inherit" />
        </IconButton>
      </div>
    </div>
  );

  const locDialog = <TrainingLocalizationDialog open={isOpen} onClose={() => setIsOpen(false)} />;

  const saveButton = <TrainingActionbarSaveButton />;


  return (
    <div className="trainingactionbar-root">
      <div className="trainingactionbar-name">
        <SchoolOutlinedIcon color="inherit" />
        <div className="trainingactionbar-training-wrapper">
          <TrainingActionbarTitle />
          {isDirty && <div className="trainingactionbar-training-unsaved">{t("unsaved_changes")}</div>}
        </div>
      </div>
      {/* {saveButton} */}
      {undoRedo}
      {testButton}
      {localizationButtons}
      {locDialog}
    </div>
  );
}
