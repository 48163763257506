import {v4 as uuidv4} from "uuid";
import {InteractionFactoryEntity, registerInteraction} from "../../features/interaction_factory/InteractionFactory";
import PiBasicQuizPreview from "../../features/previews/PiBasicQuizPreview";
import {GetMigratedProperties} from "../../features/project_migration/InteractionMigrationHelper";
import {requiredGlobalQuizProperties} from "../GlobalQuizProperty";
import {InteractionProperty} from "../InteractionProperty";
import {IAnswer, IFormQuiz} from "../Quiz";
import Interaction, {InteractionType} from "./Interaction";

export const requiredMultiSelectProperties: InteractionProperty[] = [...requiredGlobalQuizProperties];

export function CreateMultiselectQuiz(keys: string[]): IFormQuiz {
    return {
        id: uuidv4(),
        name: "Multiselect Quiz",
        type: InteractionType.MultiselectQuiz,
        title: keys[0],
        answers: [
            {id: uuidv4(), correct: true, text: keys[1]},
            {id: uuidv4(), correct: false, text: keys[2]},
        ],
        properties: requiredMultiSelectProperties,
    };
}

export function CreateMultiSelectQuizCopy(titleKey: string, answers: IAnswer[], properties: InteractionProperty[] | undefined): IFormQuiz {
    return {
        id: uuidv4(),
        name: "Multiselect Quiz",
        type: InteractionType.MultiselectQuiz,
        title: titleKey,
        answers: answers,
        properties: properties,
    };
}

export function MigrateMultiselectQuiz(from: number, to: number, interaction: IFormQuiz): IFormQuiz {
    if (interaction.properties === undefined) return {...interaction, properties: requiredMultiSelectProperties};

    var newProperties: InteractionProperty[] = GetMigratedProperties(requiredMultiSelectProperties, interaction.properties);
    console.log("[MultiSelectQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

    return {...interaction, properties: newProperties};
}

export const multiSelectQuizInteraction = (): InteractionFactoryEntity => {
    const component = <PiBasicQuizPreview multipleCorrectAnswers hasFooter/>;

    const create = (getKeys: (amount: number) => string[]): IFormQuiz => {
        const keys = getKeys(3);

        return {
            id: uuidv4(),
            name: "Multiselect Quiz",
            type: InteractionType.MultiselectQuiz,
            title: keys[0],
            answers: [
                {id: uuidv4(), correct: true, text: keys[1]},
                {id: uuidv4(), correct: false, text: keys[2]},
            ],
            properties: requiredMultiSelectProperties,
        };
    };

    const migrate = (from: number, to: number, interaction: Interaction): IFormQuiz => {
        const quizData = interaction as IFormQuiz; // need to cast

        if (interaction.properties === undefined) return {...quizData, properties: requiredMultiSelectProperties};

        var newProperties: InteractionProperty[] = GetMigratedProperties(requiredMultiSelectProperties, interaction.properties);
        console.log("[MultiSelectQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

        return {...quizData, properties: newProperties};
    };

    const copy = (interaction: Interaction, getKeys: (amount: number) => string[], replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IFormQuiz => {
        const formQuiz = interaction as IFormQuiz;
        const newKeys = getKeys(formQuiz.answers.length + 1);
        replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);
        return {
            id: uuidv4(),
            name: interaction.name,
            type: InteractionType.MultiselectQuiz,
            title: newKeys[newKeys.length - 1],
            answers: getAnswerArray(formQuiz.answers, newKeys),
            properties: formQuiz.properties,
        };
    }

    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy
    };
};


