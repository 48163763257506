import {v4 as uuidv4} from "uuid";
import {InteractionFactoryEntity, registerInteraction} from "../../features/interaction_factory/InteractionFactory";
import ConversationPreview from "../../features/previews/conversation/ConversationPreview";
import {InteractionProperty} from "../InteractionProperty";
import {emotionBubbleInteraction, IEmotionBubble} from "./EmotionBubble";
import Interaction, {InteractionType, Marker} from "./Interaction";
import {IMessageBubble, messageBubbleInteraction} from "./MessageBubble";
import {IAnswer} from "../Quiz";

export interface IConversation extends Interaction {
    messages: (IMessageBubble | IEmotionBubble)[];
}

export function CreateConversation(): IConversation {
    return {
        id: uuidv4(),
        name: "Conversation",
        type: InteractionType.Conversation,
        messages: [],
        properties: [],
    };
}

export function CreateConversationCopy(messages: (IMessageBubble | IEmotionBubble)[], properties: InteractionProperty[] | undefined): IConversation {
    return {
        id: uuidv4(),
        name: "Conversation",
        type: InteractionType.Conversation,
        messages: messages,
        properties: properties,
    };
}

export function MigrateConversation(from: number, to: number, interaction: IConversation): IConversation {
    return interaction;
}

export const conversationInteraction = (): InteractionFactoryEntity => {
    const component = <ConversationPreview/>;

    const create = (getKeys: (amount: number) => string[]): IConversation => {
        return {
            id: uuidv4(),
            name: "Conversation",
            type: InteractionType.Conversation,
            messages: [],
            properties: [],
        };
    };

    const migrate = (from: number, to: number, interaction: Interaction): IConversation => {
        return interaction as IConversation;
    };

    const copy = (interaction: Interaction,
                  getKeys: (amount: number) => string[],
                  replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IConversation => {

        const convo = interaction as IConversation;
        const numberOfKeys = convo.messages.filter((i) => i.type === InteractionType.MessageBubble).length;
        const newKeys = getKeys(numberOfKeys);
        var localCounter = -1;

        const newMessages: (IMessageBubble | IEmotionBubble)[] = convo.messages.map(message => {
            if (message.type === InteractionType.MessageBubble) {
                localCounter++;
                
                replaceKey((message as IMessageBubble).text, newKeys[localCounter]);
                
                return {
                    id: uuidv4(),
                    name: "Message Bubble",
                    type: InteractionType.MessageBubble,
                    text: newKeys[localCounter],
                    npcId: message.npcId,
                    animation: message.animation,
                    lookAt: message.lookAt,
                    messageBubbleStyle: message.messageBubbleStyle
                } as IMessageBubble
            } else if (message.type === InteractionType.EmotionBubble) {
                return emotionBubbleInteraction().Copy!(message, getKeys, replaceKey, getAnswerArray) as IEmotionBubble
                //return CreateEmotionBubbleCopy(message as IEmotionBubble);
            }
            
            return message;
        });
        
        return {
            id: uuidv4(),
            name: "Conversation",
            type: InteractionType.Conversation,
            messages: newMessages,
            properties: convo.properties,
        }
    }

    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy
    };
};


