import React from "react";
import {useTranslation} from "react-i18next";
import {Step} from "react-joyride";

import {Typography} from "@mui/material";
import avatar from "../../assets/avatar_lisa.png";

interface StepContent {
    title: string;
    description: string;
}

const StepContent = ({title, description}: StepContent) => {
    const {t} = useTranslation();

    return (
        <div id={"onboarding-stepper"} data-cy={title}>
            <img
                src={avatar}
                style={{
                    width: 48,
                    height: 48,
                    margin: "auto"
                }}
                alt="Avatar"
            />
            <Typography variant="h5" sx={{mb: 3, mt: 3, fontWeight: "bold", color: "var(--pi-text-color-1)"}}>
                {t(title)}
            </Typography>
            <Typography variant="subtitle1" sx={{color: "var(--pi-menu-text-color-1)"}}>
                {t(description)}
            </Typography>
        </div>
    );
};

export const foldersSteps: Step[] = [
    {
        disableBeacon: true,
        target: "#onboarding-fileexplorer-searchFiles",
        placement: "top",
        offset: 0,
        content: <StepContent title="onboarding.fileExplorer.step_2.title" description="onboarding.fileExplorer.step_2.content"/>,
    },
    {
        disableBeacon: true,
        target: "#filedropzone",
        placement: "right",
        offset: 10,
        content: <StepContent title="onboarding.fileExplorer.step_1.title" description="onboarding.fileExplorer.step_1.content"/>,
    },
];

export const trainingEditorSteps: Step[] = [
    {
        disableBeacon: true,
        target: "#trainingeditor-menu-button",
        placement: "right",
        offset: 0,
        content: <StepContent title="onboarding.trainingEditor.step_1.title" description="onboarding.trainingEditor.step_1.content"/>,
    },
    {
        disableBeacon: true,
        target: "#trainingeditor-save-button",
        placement: "right",
        offset: 0,
        content: <StepContent title="onboarding.trainingEditor.step_2.title" description="onboarding.trainingEditor.step_2.content"/>,
    },
    {
        disableBeacon: true,
        target: "#trainingeditor-publish-button",
        placement: "right",
        offset: 0,
        content: <StepContent title="onboarding.trainingEditor.step_3.title" description="onboarding.trainingEditor.step_3.content"/>,
    },
    {
        disableBeacon: true,
        target: "#trainingeditor-file-explorer-button",
        placement: "right",
        offset: 0,
        content: <StepContent title="onboarding.trainingEditor.step_4.title" description="onboarding.trainingEditor.step_4.content"/>,
    },
    {
        disableBeacon: true,
        target: "#trainingeditor-exit-button",
        placement: "right",
        offset: 0,
        content: <StepContent title="onboarding.trainingEditor.step_5.title" description="onboarding.trainingEditor.step_5.content"/>,
    },
    {
        disableBeacon: true,
        target: "#add-bubble-btn",
        placement: "top",
        offset: 0,
        content: <StepContent title="onboarding.trainingEditor.step_6.title" description="onboarding.trainingEditor.step_6.content"/>,
    },
];

export const dashboardSteps: Step[] = [
    {
        disableBeacon: true,
        target: "#dashboard",
        placement: "right",
        offset: 0,
        content: <StepContent title="onboarding.dashboard.step_1.title" description="onboarding.dashboard.step_1.content"/>,
    },
    {
        disableBeacon: true,
        target: "#projectstable",
        placement: "top-start",
        offset: 0,
        content: <StepContent title="onboarding.dashboard.step_2.title" description="onboarding.dashboard.step_2.content"/>,
    },
    {
        disableBeacon: true,
        target: "#newtraining-button",
        placement: "bottom",
        offset: 0,
        content: <StepContent title="onboarding.dashboard.step_3.title" description="onboarding.dashboard.step_3.content"/>,
    },
    {
        disableBeacon: true,
        target: "#newTraining",
        placement: "right",
        offset: 0,
        content: <StepContent title="onboarding.dashboard.step_4.title" description="onboarding.dashboard.step_4.content"/>,
    },
    {
        disableBeacon: true,
        target: "#fileExplorer",
        placement: "right",
        offset: 0,
        content: <StepContent title="onboarding.dashboard.step_5.title" description="onboarding.dashboard.step_5.content"/>,
    },
];

export const interactionPickerSteps: Step[] = [
    {
        disableBeacon: true,
        target: ".interactionpickeraccordion-root",
        placement: "right-start",
        offset: 0,
        content: <StepContent title="onboarding.interactionPicker.step_1.title" description="onboarding.interactionPicker.step_1.content"/>,
    },
    {
        disableBeacon: true,
        target: "#interactionpicker-search",
        placement: "bottom",
        offset: 0,
        content: <StepContent title="onboarding.interactionPicker.step_2.title" description="onboarding.interactionPicker.step_2.content"/>,
    },
    {
        disableBeacon: true,
        target: ".interactionpickercontent-selector",
        placement: "left",
        offset: 0,
        content: <StepContent title="onboarding.interactionPicker.step_3.title" description="onboarding.interactionPicker.step_3.content"/>,
    },
];

export const localizationSteps: Step[] = [
    {
        disableBeacon: true,
        target: "#traininglocalizationtable",
        placement: "bottom",
        offset: 0,
        content: <StepContent title="onboarding.localization.step_1.title" description="onboarding.localization.step_1.content"/>,
    },
    {
        disableBeacon: true,
        target: ".traininglocalizationdialog-add-button",
        placement: "bottom",
        offset: 0,
        content: <StepContent title="onboarding.localization.step_2.title" description="onboarding.localization.step_2.content"/>,
    },
];
export const addFirstBubbleSteps: Step[] = [
    {
        disableBeacon: true,
        target: ".take360cardcontent-addinteraction-button",
        placement: "right",
        offset: 0,
        content: <StepContent title="onboarding.addFirstBubble.step_1.title" description="onboarding.addFirstBubble.step_1.content"/>,
    },
    {
        disableBeacon: true,
        target: "#pi-split-button",
        placement: "bottom",
        offset: 0,
        content: <StepContent title="onboarding.addFirstBubble.step_2.title" description="onboarding.addFirstBubble.step_2.content"/>,
    },
    {
        disableBeacon: true,
        target: "#pi-split-button-dropdown",
        placement: "bottom",
        offset: 0,
        content: <StepContent title="onboarding.addFirstBubble.step_3.title" description="onboarding.addFirstBubble.step_3.content"/>,
    },
];
const Steps: Record<string, Step[]> = {
    dashboard: dashboardSteps,
    fileExplorer: foldersSteps,
    trainingEditor: trainingEditorSteps,
    interactionPicker: interactionPickerSteps,
    localization: localizationSteps,
    addFirstBubble: addFirstBubbleSteps,
};

export class OnboardingService {
    static HasVisited(context: string): boolean {
        const visited = localStorage.getItem(`visited_${context}`);
        return visited === "true";
    }

    static SetVisited(context: string): void {
        localStorage.setItem(`visited_${context}`, "true");
    }

    static GetSteps(context: string): Step[] {
        return Steps[context];
    }
}
