import {v4 as uuidv4} from "uuid";
import {InteractionFactoryEntity, registerInteraction} from "../../features/interaction_factory/InteractionFactory";
import PdfPopupPreview from "../../features/previews/PdfPopupPreview";
import Interaction, {InteractionType} from "./Interaction";

export interface PdfPopup extends Interaction {
    title: string;
    assetId: string;
}

export function CreatePdfPopup(titleKey: string, assetId?: string): PdfPopup {
    return {
        id: uuidv4(),
        name: "Pdf Popup",
        type: InteractionType.PdfPopup,
        title: titleKey,
        assetId: assetId ?? "",
    };
}

export function MigratePdfPopup(from: number, to: number, interaction: PdfPopup): PdfPopup {
    return interaction;
}

export const pdfPopupInteraction = (): InteractionFactoryEntity => {
    const component = <PdfPopupPreview/>;

    const create = (getKeys: (amount: number) => string[]): PdfPopup => {
        const keys = getKeys(1);
        return {
            id: uuidv4(),
            name: "Pdf Popup",
            type: InteractionType.PdfPopup,
            title: keys[0],
            assetId: "",
        };
    };

    const migrate = (from: number, to: number, interaction: Interaction): PdfPopup => {
        return interaction as PdfPopup;
    };

    const copy = (interaction: Interaction, getKeys: (amount: number) => string[], replaceKey: (from: string, to: string) => void): PdfPopup => {

        const title = getKeys(1)[0];
        replaceKey((interaction as PdfPopup).title, title);
        return {
            id: uuidv4(),
            name: "Pdf Popup",
            type: InteractionType.PdfPopup,
            title: title,
            assetId: (interaction as PdfPopup).assetId,
        };
    }

    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy
    }
}

