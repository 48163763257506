import {InteractionType} from "../../models/interactions/Interaction";

export enum InteractionCategory {
    Popups = "Popups",
    Quizes = "Quizes",
    Other = "Other",
}

export enum InteractionIcon {
    Message = "message",
    Image = "image",
    Video = "video",
    Pdf = "pdf",
    MultipleChoiceQuiz = "multipleChoiceQuiz",
    MultiselectQuiz = "multiselectQuiz",
    OrderQuiz = "orderQuiz",
    ConnectQuiz = "connectQuiz",
    Marker = "marker",
    WordSpinnerQuiz = "wordSpinnerQuiz",
    ClickRegion = "clickRegion",
    PhotoRangeQuiz = "photoRangeQuiz",
    AmbientAudio = "ambientAudio",
    AudioPopup = "audioPopup",
    Camera = "camera",
}

export interface InteractionDefintion {
    name: string;
    description: string;
    icon: InteractionIcon;
    type: InteractionType;
    category: InteractionCategory;
    previewImage: string;
}

const LisaMessageDefinition: InteractionDefintion = {
    name: "Lisa Message",
    description: "lisa_message",
    icon: InteractionIcon.Message,
    type: InteractionType.LisaMessage,
    category: InteractionCategory.Other,
    previewImage: "lisa_message.png",
};

const MessagePopupDefinition: InteractionDefintion = {
    name: "Message Popup",
    description: "message_popup",
    icon: InteractionIcon.Message,
    type: InteractionType.MessagePopup,
    category: InteractionCategory.Popups,
    previewImage: "message_popup.png",
};

const ImagePopupDefinition: InteractionDefintion = {
    name: "Image Popup",
    description: "image_popup",
    icon: InteractionIcon.Image,
    type: InteractionType.ImagePopup,
    category: InteractionCategory.Popups,
    previewImage: "image_popup.png",
};

const VideoPopupDefinition: InteractionDefintion = {
    name: "Video Popup",
    description: "video_popup",
    icon: InteractionIcon.Video,
    type: InteractionType.VideoPopup,
    category: InteractionCategory.Popups,
    previewImage: "video_popup.png",
};

const PdfPopupDefinition: InteractionDefintion = {
    name: "Pdf Popup",
    description: "pdf_popup",
    icon: InteractionIcon.Pdf,
    type: InteractionType.PdfPopup,
    category: InteractionCategory.Popups,
    previewImage: "pdf_preview.png",
};

const MultipleChoiceQuizDefinition: InteractionDefintion = {
    name: "Multiple Choice Quiz",
    description: "multiple_choice_quiz",
    icon: InteractionIcon.MultipleChoiceQuiz,
    type: InteractionType.MultipleChoiceQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "multiple_choice_quiz.png",
};

const MultiselectQuizDefinition: InteractionDefintion = {
    name: "MultiSelect Quiz",
    description: "multi_select_quiz",
    icon: InteractionIcon.MultiselectQuiz,
    type: InteractionType.MultiselectQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "multiselect_quiz.png",
};

const MultipleChoiceGraphicsQuizDefinition: InteractionDefintion = {
    name: "Multiple Choice Graphics Quiz",
    description: "multiple_choice_gfx_quiz",
    icon: InteractionIcon.MultipleChoiceQuiz,
    type: InteractionType.MultipleChoiceGraphicsQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "multiple_choice_graphics_quiz.png",
};

const MultiSelectGraphicsQuizDefinition: InteractionDefintion = {
    name: "MultiSelect Graphics Quiz",
    description: "multi_select_gfx_quiz",
    icon: InteractionIcon.MultiselectQuiz,
    type: InteractionType.MultiSelectGraphicsQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "multiselect_graphics_quiz.png",
};

const OrderQuizDefinition: InteractionDefintion = {
    name: "Order Quiz",
    description: "order_quiz",
    icon: InteractionIcon.OrderQuiz,
    type: InteractionType.OrderQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "order_quiz.png",
};

const OrderGraphicsQuizDefinition: InteractionDefintion = {
    name: "Order Graphics Quiz",
    description: "order_gfx_quiz",
    icon: InteractionIcon.OrderQuiz,
    type: InteractionType.OrderGraphicsQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "order_graphics_quiz.png",
};

const ConnectQuizDefinition: InteractionDefintion = {
    name: "Connect Quiz",
    description: "connect_quiz",
    icon: InteractionIcon.ConnectQuiz,
    type: InteractionType.ConnectQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "connect_quiz.png",
};

const ConnectGraphicsQuizDefinition: InteractionDefintion = {
    name: "Connect Graphics Quiz",
    description: "connect_gfx_quiz",
    icon: InteractionIcon.ConnectQuiz,
    type: InteractionType.ConnectGraphicsQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "connect_graphics_quiz.png",
};

const MarkerDefinition: InteractionDefintion = {
    name: "Marker",
    description: "marker",
    icon: InteractionIcon.Marker,
    type: InteractionType.Marker,
    category: InteractionCategory.Other,
    previewImage: "marker.png",
};

const ScrabbleQuizDefinition: InteractionDefintion = {
    name: "Scrabble Quiz",
    description: "scrabble_quiz",
    icon: InteractionIcon.MultipleChoiceQuiz,
    type: InteractionType.ScrabbleQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "scrabble_quiz.png",
};

const WordSpinnerQuizDefinition: InteractionDefintion = {
    name: "Word Spinner Quiz",
    description: "wordspinner_quiz",
    icon: InteractionIcon.WordSpinnerQuiz,
    type: InteractionType.WordSpinnerQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "word_spinner_quiz.png",
};

const ClickRegionDefinition: InteractionDefintion = {
    name: "Click Region",
    description: "clickregion",
    icon: InteractionIcon.ClickRegion,
    type: InteractionType.ClickRegion,
    category: InteractionCategory.Other,
    previewImage: "click_region.png",
};

const ClickRegionOrderQuizDefinition: InteractionDefintion = {
    name: "Click Region Order Quiz",
    description: "clickregion_order_quiz",
    icon: InteractionIcon.ClickRegion,
    type: InteractionType.ClickRegionOrderQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "clickregion_order_quiz.png",
};

const ClickRegionMultiSelectQuizDefinition: InteractionDefintion = {
    name: "Click Region Multiple Choice Quiz",
    description: "clickregion_multi_select_quiz",
    icon: InteractionIcon.ClickRegion,
    type: InteractionType.ClickRegionMultiSelectQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "clickregion_ms_quiz.png",
};

const WordQuizDefinition: InteractionDefintion = {
    name: "Word Quiz",
    description: "word_quiz",
    icon: InteractionIcon.MultipleChoiceQuiz,
    type: InteractionType.WordQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "word_quiz.png",
};

const PhotoRangeQuizDefinition: InteractionDefintion = {
    name: "Photo Range Quiz",
    description: "photorange_quiz",
    icon: InteractionIcon.PhotoRangeQuiz,
    type: InteractionType.PhotoRangeQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "photo_range_quiz.png",
};

const AmbientAudioDefinition: InteractionDefintion = {
    name: "Ambient Audio",
    description: "ambient_audio",
    icon: InteractionIcon.AmbientAudio,
    type: InteractionType.AmbientAudio,
    category: InteractionCategory.Other,
    previewImage: "ambient_audio.png",
};

const AudioPopupDefinition: InteractionDefintion = {
    name: "Audio Popup",
    description: "audio_popup",
    icon: InteractionIcon.AudioPopup,
    type: InteractionType.AudioPopup,
    category: InteractionCategory.Popups,
    previewImage: "audio_popup.png",
};

const ConversationDefinition: InteractionDefintion = {
    name: "Conversation",
    description: "conversation",
    icon: InteractionIcon.Message,
    type: InteractionType.Conversation,
    category: InteractionCategory.Popups,
    previewImage: "conversation.png",
};

const SetCameraDefinition: InteractionDefintion = {
    name: "Set Camera",
    description: "camera",
    icon: InteractionIcon.Camera,
    type: InteractionType.SetCamera,
    category: InteractionCategory.Other,
    previewImage: "coming_soon.png",
};

const MessageBubbleDefinition: InteractionDefintion = {
    name: "Message Bubble",
    description: "messageBubble",
    icon: InteractionIcon.Message,
    type: InteractionType.MessageBubble,
    category: InteractionCategory.Other,
    previewImage: "message_bubble_preview.png",
};

const EmotionBubbleDefintion: InteractionDefintion = {
    name: "Emotion Bubble",
    description: "emotionBubble",
    icon: InteractionIcon.Message,
    type: InteractionType.EmotionBubble,
    category: InteractionCategory.Other,
    previewImage: "emotion_bubble_preview.png",
};

const DialogQuizDefinition: InteractionDefintion = {
    name: "Dialog Quiz",
    description: "dialogQuiz",
    icon: InteractionIcon.MultiselectQuiz,
    type: InteractionType.DialogQuiz,
    category: InteractionCategory.Quizes,
    previewImage: "dialog_quiz.png",
}

const GrabDefinition: InteractionDefintion = {
    name: "Grab",
    description: "grab",
    icon: InteractionIcon.MultiselectQuiz,
    type: InteractionType.Grab,
    category: InteractionCategory.Other,
    previewImage: "coming_soon.png",
}

// TODO: do this differently
// becomes tedious to manage!

export const BasicInteractions360 = [
    MessagePopupDefinition,
    LisaMessageDefinition,
    ImagePopupDefinition,
    VideoPopupDefinition,
    PdfPopupDefinition,
    MultipleChoiceQuizDefinition,
    MultiselectQuizDefinition,
    MultipleChoiceGraphicsQuizDefinition,
    MultiSelectGraphicsQuizDefinition,
    OrderQuizDefinition,
    OrderGraphicsQuizDefinition,
    ConnectQuizDefinition,
    ConnectGraphicsQuizDefinition,
    MarkerDefinition,
    ScrabbleQuizDefinition,
    WordSpinnerQuizDefinition,
    ClickRegionDefinition,
    ClickRegionOrderQuizDefinition,
    ClickRegionMultiSelectQuizDefinition,
    WordQuizDefinition,
    PhotoRangeQuizDefinition,
    AmbientAudioDefinition,
    AudioPopupDefinition,
];

export const BasicInteractions3D = [
    MessagePopupDefinition,
    LisaMessageDefinition,
    ImagePopupDefinition,
    VideoPopupDefinition,
    PdfPopupDefinition,
    MultipleChoiceQuizDefinition,
    MultiselectQuizDefinition,
    MultipleChoiceGraphicsQuizDefinition,
    MultiSelectGraphicsQuizDefinition,
    OrderQuizDefinition,
    OrderGraphicsQuizDefinition,
    ConnectQuizDefinition,
    ConnectGraphicsQuizDefinition,
    ScrabbleQuizDefinition,
    WordSpinnerQuizDefinition,
    WordQuizDefinition,
    PhotoRangeQuizDefinition,
    AmbientAudioDefinition,
    AudioPopupDefinition,
    ConversationDefinition,
    MessageBubbleDefinition,
    EmotionBubbleDefintion,
    DialogQuizDefinition,
    GrabDefinition
];

export const AllInteractions = [
    MessagePopupDefinition,
    LisaMessageDefinition,
    ImagePopupDefinition,
    VideoPopupDefinition,
    PdfPopupDefinition,
    MultipleChoiceQuizDefinition,
    MultiselectQuizDefinition,
    MultipleChoiceGraphicsQuizDefinition,
    MultiSelectGraphicsQuizDefinition,
    OrderQuizDefinition,
    OrderGraphicsQuizDefinition,
    ConnectQuizDefinition,
    ConnectGraphicsQuizDefinition,
    MarkerDefinition,
    ScrabbleQuizDefinition,
    WordSpinnerQuizDefinition,
    ClickRegionDefinition,
    ClickRegionOrderQuizDefinition,
    ClickRegionMultiSelectQuizDefinition,
    WordQuizDefinition,
    PhotoRangeQuizDefinition,
    AmbientAudioDefinition,
    AudioPopupDefinition,
    ConversationDefinition,
    SetCameraDefinition,
    MessageBubbleDefinition,
    EmotionBubbleDefintion,
    DialogQuizDefinition,
    GrabDefinition
];
