import {useSelector} from "react-redux";
import {selectProps, selectUsedProps} from "../app/slices/PropLibrarySlice";
import Take3D from "../models/Take3D";
import {InteractionCategory, InteractionDefintion, InteractionIcon} from "../features/interaction_picker/InteractionDefinitions";
import {InteractionType} from "../models/interactions/Interaction";
import {selectAllPropPresets, selectSelectedTake3D} from "../app/slices/TrainingSlice";

export default function usePropHelper() {
    const allPropDtos = useSelector(selectProps); // all props
    const allPropPresets = useSelector(selectAllPropPresets);
    const take = useSelector(selectSelectedTake3D);
    const usedProps = useSelector(selectUsedProps);
    
    const getPropDTO = (propId: string) => {
        const prop = usedProps[propId];
        if (!prop) throw new Error("Prop not found");

        return prop;
    }

    const getUsedPropByInstanceId = (instanceId: string) => {
        const inst = allPropPresets.find((p) => p.id === instanceId);
        const dto = getPropDTO(inst?.propId ?? "");
       
        if (!dto) throw new Error("Prop not found");

        return dto;
    }

    const getInvolvedPropInteractions = (take: Take3D) => {
        const involvedProps = take.props;
        const definitions: Record<string, InteractionDefintion[]> = {};
        
        involvedProps.forEach((involvedProp) => {
            const preset = allPropPresets.find((p) => p.id === involvedProp.instanceId);
            if(!preset) return;
            const usedProp = usedProps[preset.propId];

            if (usedProp && usedProp.metadata.dynamicInteractions) {
                let localDefinitions: InteractionDefintion[] = [];
                usedProp.metadata.dynamicInteractions.forEach((interaction) => {
                    localDefinitions.push({
                        name: interaction.title,
                        icon: InteractionIcon.Message,
                        type: InteractionType.DynamicInteraction,
                        category: InteractionCategory.Quizes,
                        previewImage: "",
                        description: interaction.description,
                    })
                });

                definitions[involvedProp.instanceId] = localDefinitions;
            }            
        });

        return definitions;
    }


    return {getPropDTO, getUsedPropByInstanceId, getInvolvedPropInteractions}
}