import React from "react";
import { DragDropContext, DragUpdate, Draggable, DraggingStyle, DropResult, Droppable, NotDraggingStyle } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { reorderInteractions, reorderTakes, selectFilteredTakes, selectSelectedTakeId, updateSelectedTake } from "../../app/slices/TrainingSlice";
import Take360Card from "./Take360Card";
import "./TrainingTakeList.css";
export default function TrainingTakeList() {
  const takes = useSelector(selectFilteredTakes);
  const selectedTakeId = useSelector(selectSelectedTakeId);
  const dispatch = useDispatch();

  const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
    padding: "1px",
    cursor: "pointer",
    ...draggableStyle,
  });

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    if (result.type === "take") {
      dispatch(
        reorderTakes({
          startIndex: result.source.index,
          endIndex: result.destination.index,
        })
      );
    } else if (result.type === "interaction") {
      dispatch(reorderInteractions({ startTakeId: result.source.droppableId, destinationTakeId: result.destination.droppableId, startIndex: result.source.index, endIndex: result.destination.index }));
    }
  }

  function ForceExpand(takeId: string) {
    var take = takes.find((take) => take.id === takeId);
    if (take && !take.isExpanded) {
      var updatedTake = { ...take };
      updatedTake.isExpanded = true;
      dispatch(updateSelectedTake(updatedTake));
    }
  }

  function onDragUpdate(dragUpdate: DragUpdate) {
    console.log(dragUpdate);
    if (dragUpdate.destination) {
      ForceExpand(dragUpdate.destination.droppableId);
    }
  }

  const takesviewdnd = (
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      <Droppable droppableId="takes" type="take">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {takes.map((take, index) => (
              <Draggable key={take.id} draggableId={take.id} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                    <Take360Card key={index} take={take} isSelected={take.id === selectedTakeId} dragHandleProps={provided.dragHandleProps} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  return <div>{takesviewdnd}</div>;
}
