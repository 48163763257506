import {useDispatch, useSelector} from "react-redux";
import {selectToken} from "../app/slices/AuthenticationSlice";
import {setIsLoading} from "../app/slices/GlobalSlice";
import {setCurrentTrainingId} from "../app/slices/ProjectsSlice";
import {addLocalizedKeys, addLocalizedLanguage, addSerializedLanguage, addSupportedLanguages, clearLocalization, setInitialLanguage} from "../app/slices/TrainingLocalizationSlice";
import {setReferenceLanguage, setTrainingDescription, setTrainingName, setTrainingType} from "../app/slices/TrainingSlice";
import {selectCurrentOrganization} from "../app/slices/UserSlice";
import {TrainingContentDTO} from "../dto/TrainingContentDTO";
import {Training} from "../models/Training";
import {TrainingService} from "../services/TrainingService";
import useLoadTraining360Content from "./LoadTraining360ContentHook";
import useLoadTraining3DContent from "./LoadTraining3DContentHook";

export const useLoadTraining = () => {
    const dispatch = useDispatch();
    const orgId = useSelector(selectCurrentOrganization)?.id;
    const token = useSelector(selectToken);
    const {loadTraining360Content} = useLoadTraining360Content();
    const {loadTraining3DContent} = useLoadTraining3DContent();

    const loadTraining = async (trainingId: string) => {
        if (!orgId) return;

        dispatch(setIsLoading(true));

        var trainingDTO = await TrainingService.GetTrainingContent(orgId, token, trainingId);
        var trainingContent: Training | undefined = await loadTrainingContent(trainingDTO);
        await loadTrainingLocalizations(trainingId, trainingContent?.refLanguage ?? "");

        dispatch(setIsLoading(false));

        // needed to check if load was succesful or not
        return trainingDTO !== undefined;
    };

    const loadTrainingContent = async (trainingContent: TrainingContentDTO | undefined): Promise<Training | undefined> => {
        if (!trainingContent) return undefined;

        // required maintenance
        dispatch(setCurrentTrainingId(trainingContent.id));
        dispatch(setTrainingName(trainingContent.name));
        dispatch(setTrainingDescription(trainingContent.description));
        dispatch(setTrainingType(trainingContent.type));

        // loading the actual data
        var training = await TrainingService.GetTrainingJson(trainingContent.downloadUri);
        if (!training) return undefined;

        if (trainingContent.type === 1) {
            await loadTraining360Content(training);
        } else {
            await loadTraining3DContent(training, token!, orgId!);
        }

        return training;
    };

    const loadTrainingLocalizations = async (trainingId: string, referenceLanguage: string) => {
        // load supported languages
        var languages = await TrainingService.GetLanguages(token);
        dispatch(addSupportedLanguages(languages));

        // load training translations
        var trainingTranslations = await TrainingService.GetTrainingTranslations(orgId!, token, trainingId);

        if (trainingTranslations.length > 0) {
            // prep: get all keys
            var keys: string[] = [];
            trainingTranslations[0].entries.forEach((entry) => keys.push(entry.key));

            // 1. clear localization
            dispatch(clearLocalization());
            // 2. add all keys
            dispatch(addLocalizedKeys(keys));
            // 3. add all languages
            trainingTranslations.forEach((transl) => dispatch(addSerializedLanguage(transl)));

            // 4. set to ref language
            var initialLanguage: string = trainingTranslations[0].lang;

            // 5. if we have a reference language and that language is in the translations, use it as initial language
            if (referenceLanguage !== "" && trainingTranslations.find((t) => t.lang === referenceLanguage)) {
                initialLanguage = referenceLanguage;
            }
            // 6. set initial language & reference language
            dispatch(setReferenceLanguage(initialLanguage));
            dispatch(setInitialLanguage(initialLanguage));
        }
        // no translations: happens when it is a new project
        else {
            // 1. clear localization
            dispatch(clearLocalization());
            // 2. we should always have a reference language
            if (referenceLanguage) {
                var lang = languages.find((lang) => lang.id === referenceLanguage)!;

                dispatch(setReferenceLanguage(referenceLanguage));
                dispatch(addLocalizedLanguage({shortcode: lang.id, full: lang.name}));
                dispatch(setInitialLanguage(referenceLanguage));
            }
        }
    };

    return [loadTraining] as const;
};
