import {Prop} from "../../models/Prop";
import usePropHelper from "../../hooks/PropHelper";
import React, {useMemo} from "react";
import PiPopoverMenu from "../../components/PiPopoverMenu";
import PiMenuButton from "../menu/PiMenuButton";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {deletePropPreset, selectSelectedPropPresetId, setSelectedPropPreset} from "../../app/slices/TrainingSlice";
import classNames from "classnames";

export default function PropCard(props: { prop: Prop }) {
    const {prop} = {...props}
    const {getPropDTO} = usePropHelper();
    const dto = useMemo(() => getPropDTO(prop.propId), [prop.propId]);
    const {t} = useTranslation();
    const selectedPropId = useSelector(selectSelectedPropPresetId);
    const isSelected = useMemo(() => selectedPropId === prop.id, [selectedPropId, prop.id]);
    const dispatch = useDispatch();

    // TODO: delete !

    const onClick = () => {
        dispatch(setSelectedPropPreset(prop.id));
    }

    const onDelete = () => {
        dispatch(deletePropPreset(prop.id));
    }

    const menu = (
        <div className={"my-auto"}>
            <PiPopoverMenu>
                <PiMenuButton text={t("delete")} onClick={onDelete}/>
                <></>
            </PiPopoverMenu>
        </div>
    );


    return <div className={classNames({
        "w-full h-[60px] flex gap-[10px] p-[10px] border-[2px] border-solid cursor-pointer rounded-[5px] select-none hover:border-creator-primary-hover": true,
        "border-creator-primary text-creator-primary font-bold": isSelected,
        "border-creator-border text-creator-text-sub ": !isSelected,
    })} onClick={onClick}>
        <div className={classNames({
            "my-auto w-[36px] h-[36px] p-[4px] rounded-full border-solid border-[2px] drop-shadow-lg ": true,
            "border-creator-primary": isSelected,
            "border-creator-border": !isSelected,
        })}>
            <img className={"w-full h-full object-contain"} src={dto.propContent.downloadUri} alt={prop.propId}/>
        </div>
        <div className={classNames({
            "text-14 my-auto truncate flex-1": true,
            "font-bold": isSelected,
            "font-medium": !isSelected
        })}>{prop.name}</div>
        {menu}
    </div>
}