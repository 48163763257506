import { v4 as uuidv4 } from "uuid";
import { InteractionFactoryEntity, registerInteraction } from "../../features/interaction_factory/InteractionFactory";
import ConnectQuizPreview from "../../features/previews/connect_quiz/ConnectQuizPreview";
import { GetMigratedProperties } from "../../features/project_migration/InteractionMigrationHelper";
import { requiredGlobalQuizProperties } from "../GlobalQuizProperty";
import { InteractionProperty } from "../InteractionProperty";
import {IAnswer, IFormQuiz} from "../Quiz";
import Interaction, { InteractionType } from "./Interaction";

export interface IConnectQuiz extends Interaction {
  title: string;
  answersA: IAnswer[];
  answersB: IAnswer[];
  connections: IConnection[];
}

export interface IConnection {
  idA: string;
  idB: string;
}

export const requiredConnectProperties: InteractionProperty[] = [...requiredGlobalQuizProperties];

export function CreateConnectQuiz(keys: string[]): IConnectQuiz {
  return {
    id: uuidv4(),
    name: "Connect Quiz",
    type: InteractionType.ConnectQuiz,
    title: keys[0],
    answersA: [
      { id: uuidv4(), correct: false, text: keys[1] },
      { id: uuidv4(), correct: false, text: keys[2] },
    ],
    answersB: [
      { id: uuidv4(), correct: false, text: keys[3] },
      { id: uuidv4(), correct: false, text: keys[4] },
    ],
    connections: [],
    properties: requiredConnectProperties,
  };
}

export function CreateConnectQuizCopy(titleKey: string, answersA: IAnswer[], answersB: IAnswer[], connections: IConnection[], properties: InteractionProperty[] | undefined): IConnectQuiz {
  return {
    id: uuidv4(),
    name: "Connect Quiz",
    type: InteractionType.ConnectQuiz,
    title: titleKey,
    answersA: answersA,
    answersB: answersB,
    connections: connections,
    properties: properties,
  };
}

export function MigrateConnectQuiz(from: number, to: number, interaction: IConnectQuiz): IConnectQuiz {
  if (interaction.properties === undefined) return { ...interaction, properties: requiredConnectProperties };

  var newProperties: InteractionProperty[] = GetMigratedProperties(requiredConnectProperties, interaction.properties);
  console.log("[ConnectQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

  return { ...interaction, properties: newProperties };
}

export const connectQuizInteraction = (): InteractionFactoryEntity => {
  const component = <ConnectQuizPreview />;

  const create = (getKeys: (amount: number) => string[]): IConnectQuiz => {
    const keys = getKeys(5);

    return {
      id: uuidv4(),
      name: "Connect Quiz",
      type: InteractionType.ConnectQuiz,
      title: keys[0],
      answersA: [
        { id: uuidv4(), correct: false, text: keys[1] },
        { id: uuidv4(), correct: false, text: keys[2] },
      ],
      answersB: [
        { id: uuidv4(), correct: false, text: keys[3] },
        { id: uuidv4(), correct: false, text: keys[4] },
      ],
      connections: [],
      properties: requiredConnectProperties,
    };
  };

  const migrate = (from: number, to: number, interaction: Interaction): IConnectQuiz => {
    const quizData = interaction as IConnectQuiz; // need to cast
    if (interaction.properties === undefined) return { ...quizData, properties: requiredConnectProperties };

    var newProperties: InteractionProperty[] = GetMigratedProperties(requiredConnectProperties, interaction.properties);
    console.log("[ConnectQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

    return { ...quizData, properties: newProperties };
  };

  const copy = (interaction: Interaction,
                getKeys: (amount: number) => string[],
                replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IConnectQuiz => {
    const formQuiz = interaction as IConnectQuiz;
    
    const newKeys = getKeys(formQuiz.answersA.length + formQuiz.answersB.length + 1);
    
    replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);
    return {
      id: uuidv4(),
      name: interaction.name,
      type: InteractionType.ConnectQuiz,
      title: newKeys[newKeys.length - 1],
      answersA: getAnswerArray(formQuiz.answersA, newKeys),
      answersB: getAnswerArray(formQuiz.answersB, newKeys.slice(formQuiz.answersA.length)),
      connections: formQuiz.connections,
      properties: formQuiz.properties,
    };
  }
  
  return {
    View: component,
    Create: create,
    Migrate: migrate,
    Copy: copy
  };
};

