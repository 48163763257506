import React from "react";
import "./PiInteractionTitlePreview.css";
import PiQuizOutlineIcon from "../../../icons/PiQuizOutlineIcon";

interface PiInteractionTitlePreviewProps {
    value: string;
    onChange: (newValue: string) => void;
    placeholder: string;
    showQuizIcon?: boolean;
    showStarIcons?: boolean;
}

export default function PiInteractionTitlePreview(props: PiInteractionTitlePreviewProps) {
    const {value, onChange, placeholder, showQuizIcon = false, showStarIcons = false} = props;

    return (
        <div className={"w-full h-[60px] flex gap-[16px] px-[20px] bg-interaction-softBackground border-b-2 border-solid border-interaction-primary hover:bg-interaction-primary-washed"}>
            <div className={"my-auto text-interaction-primary text-[30px] font-semibold"}>
                <PiQuizOutlineIcon className={"fill-interaction-primary"} height={30} width={30}/>
            </div>
            <div className={"w-full h-[30px] my-auto bg-none flex"}>
                <input className={"bg-transparent my-auto w-full font-semibold text-[20px] text-interaction-primary truncate placeholder:text-interaction-text-placeholder focus:outline-none"}
                       aria-label="piinteractiontitlepreview-input" value={value}
                       onChange={(e) => onChange(e.target.value)}
                       placeholder={placeholder}/>
            </div>
        </div>
    );
}
