import { v4 as uuidv4 } from "uuid";
import { CreateCustomClickRegion, IClickRegion } from "./ClickRegion";
import Interaction, {InteractionType, Marker} from "./Interaction";
import {InteractionFactoryEntity, registerInteraction} from "../../features/interaction_factory/InteractionFactory";
import ClickRegionMultiSelectQuizPreview from "../../features/previews/click_region_multi_select_quiz/ClickRegionMultiSelectQuizPreview";
import {IAnswer} from "../Quiz";

export interface IClickRegionMultiSelectQuiz extends Interaction {
  title: string;
  clickregions: IClickRegion[];
}

export function CreateClickRegionMultiSelectQuiz(key: string): IClickRegionMultiSelectQuiz {
  return {
    id: uuidv4(),
    name: "Click Region Multi Select Quiz",
    type: InteractionType.ClickRegionMultiSelectQuiz,
    title: key,
    clickregions: [CreateCustomClickRegion(0.4, 0.5, 0.1, 0.1), CreateCustomClickRegion(0.6, 0.5, 0.1, 0.1)],
  };
}

export function MigrateClickRegionMultiSelectQuiz(from: number, to: number, interaction: IClickRegionMultiSelectQuiz): IClickRegionMultiSelectQuiz {
  return interaction;
}

export const clickRegionMultiSelectQuizInteraction = (): InteractionFactoryEntity => {
  const component = <ClickRegionMultiSelectQuizPreview />;

  const create = (getKeys: (amount: number) => string[]): IClickRegionMultiSelectQuiz => {
    const keys = getKeys(1);
    return {
      id: uuidv4(),
      name: "Click Region Multi Select Quiz",
      type: InteractionType.ClickRegionMultiSelectQuiz,
      title: keys[0],
      clickregions: [CreateCustomClickRegion(0.4, 0.5, 0.1, 0.1), CreateCustomClickRegion(0.6, 0.5, 0.1, 0.1)],
    };
  }
  const migrate = (from: number, to: number, interaction: Interaction): IClickRegionMultiSelectQuiz => {
    return interaction as IClickRegionMultiSelectQuiz;
  }

  const copy = (interaction: Interaction,
                getKeys: (amount: number) => string[],
                replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IClickRegionMultiSelectQuiz => {
    return create(getKeys);
  }
  
  return {
    View: component,
    Create: create,
    Migrate: migrate,
    Copy: copy
  }
}

