import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TakePosition } from "../../app/slices/EnvironmentSlice";
import { selectSelectedTake3D, selectTakes3D, updateSelectedTake } from "../../app/slices/TrainingSlice";
import Take3D from "../../models/Take3D";
import InteractionPointPreview, { InteractionPointStrategy, SlatePointStrategy } from "./InteractionPointPreview";
import { Viewport3DLayerProps } from "./Viewport3DEnvironmentMetadata";

export default function Viewport3DTakeLocationLayer(props: Viewport3DLayerProps) {
  const dispatch = useDispatch();
  const take = useSelector(selectSelectedTake3D);
  const allTakes = useSelector(selectTakes3D);
  const { currentTakeLocation, takePositions, isCameraSelected } = { ...props };

  const onSelectInteractionLocation = (interactionPoint: TakePosition) => {
    if (!take) return;
    var camId: string = interactionPoint.camera_positions[0].id;

    //Check wether there is a previous take, and whether is has the same take position
    // use default cam pos if conditions are not met
    camId = allTakes.length > 1 && allTakes[allTakes.length - 2].interactionPointId === interactionPoint.id ? allTakes[allTakes.length - 2].mainCamera.cameraId : camId;

    const cpy: Take3D = { ...take, interactionPointId: interactionPoint.id, name: interactionPoint.name, isExpanded: true, mainCamera: { ...take.mainCamera, cameraId: camId } };
    
    dispatch(updateSelectedTake(cpy));
  };

  const allTakeLocations = takePositions.map((point, index) => {
    return <InteractionPointPreview strategy={InteractionPointStrategy} x={point.position.x} y={point.position.y} key={index} onClick={() => onSelectInteractionLocation(point)} />;
  });

  const currentTakeLocationSlate = <InteractionPointPreview strategy={SlatePointStrategy} x={currentTakeLocation?.position.x ?? -1} y={currentTakeLocation?.position.y ?? -1} />;

  return (
    <>
      {!isCameraSelected && take && !take.interactionPointId && allTakeLocations}
      {!isCameraSelected && take && take.interactionPointId && currentTakeLocationSlate}
    </>
  );
}
