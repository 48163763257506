import { v4 as uuidv4 } from "uuid";
import { InteractionFactoryEntity, registerInteraction } from "../../features/interaction_factory/InteractionFactory";
import LisaMessagePreview from "../../features/previews/LisaMessagePreview";
import Interaction, { InteractionType } from "./Interaction";

export interface LisaMessage extends Interaction {
  message: string;
}

export function CreateLisaMessage(messageKey: string): LisaMessage {
  return {
    id: uuidv4(),
    name: "Lisa Message",
    type: InteractionType.LisaMessage,
    message: messageKey,
  };
}

export function MigrateLisaMessage(from: number, to: number, interaction: LisaMessage): LisaMessage {
  return interaction;
}

export const lisaMessageInteraction = (): InteractionFactoryEntity => {
  const component = <LisaMessagePreview />;

  const create = (getKeys: (amount: number) => string[]): LisaMessage => {
    const keys = getKeys(1);

    return {
      id: uuidv4(),
      name: "Lisa Message",
      type: InteractionType.LisaMessage,
      message: keys[0],
    };
  };

  const migrate = (from: number, to: number, interaction: Interaction): LisaMessage => {
    return interaction as LisaMessage;
  };

  const copy = (interaction: Interaction, getKeys: (amount: number) => string[], replaceKey: (from: string, to: string) => void): LisaMessage => {
    const key = getKeys(1)[0];
    replaceKey((interaction as LisaMessage).message, key);
    return {
      id: uuidv4(),
      name: "Lisa Message",
      type: InteractionType.LisaMessage,
      message: key,
    }
  };
  
  return {
    View: component,
    Create: create,
    Migrate: migrate,
    Copy: copy,
  };
};


