import {v4 as uuidv4} from "uuid";
import Interaction, {InteractionType} from "./Interaction";
import {GetMigratedProperties} from "../../features/project_migration/InteractionMigrationHelper";
import {InteractionProperty, InteractionPropertyType} from "../InteractionProperty";
import {requiredGlobalQuizProperties} from "../GlobalQuizProperty";
import {IAnswer, IFormQuiz} from "../Quiz";
import {InteractionFactoryEntity, registerInteraction} from "../../features/interaction_factory/InteractionFactory";
import WordQuizPreview from "../../features/previews/WordQuiz/WordQuizPreview";

export interface IWordQuiz extends IFormQuiz {
    formattedSentence: string;
}

export const requiredWordProperties: InteractionProperty[] = [...requiredGlobalQuizProperties];

export function CreateWordQuiz(keys: string[]): IWordQuiz {
    return {
        id: uuidv4(),
        name: "Word Quiz",
        type: InteractionType.WordQuiz,
        title: keys[0],
        formattedSentence: keys[2],
        answers: [],
        properties: requiredWordProperties,
    };
}

export function CreateWordQuizCopy(
    titleKey: string,
    formattedSentenceKey: string,
    answers: IAnswer[],
    properties: InteractionProperty[] | undefined
): IWordQuiz {
    return {
        id: uuidv4(),
        name: "Word Quiz",
        type: InteractionType.WordQuiz,
        title: titleKey,
        formattedSentence: formattedSentenceKey,
        answers: answers,
        properties: properties,
    };
}

export function MigrateWordQuiz(from: number, to: number, interaction: IWordQuiz): IWordQuiz {
    if (interaction.properties === undefined) return {...interaction, properties: requiredWordProperties};

    var newProperties: InteractionProperty[] = GetMigratedProperties(requiredWordProperties, interaction.properties);
    console.log("[WordQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

    return {...interaction, properties: newProperties};
}

export const wordQuizInteraction = (): InteractionFactoryEntity => {
    const component = <WordQuizPreview/>;

    const create = (getKeys: (amount: number) => string[]): IWordQuiz => {
        const keys = getKeys(2);
        return {
            id: uuidv4(),
            name: "Word Quiz",
            type: InteractionType.WordQuiz,
            title: keys[0],
            formattedSentence: keys[1],
            answers: [],
            properties: requiredWordProperties,
        };
    }
    const migrate = (from: number, to: number, interaction: Interaction): IWordQuiz => {
        const quizData = interaction as IWordQuiz;
        if (interaction.properties === undefined) return {...quizData, properties: requiredWordProperties};

        var newProperties: InteractionProperty[] = GetMigratedProperties(requiredWordProperties, interaction.properties);
        console.log("[WordQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

        return {...quizData, properties: newProperties};
    }

    const copy = (interaction: Interaction,
                  getKeys: (amount: number) => string[],
                  replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IWordQuiz => {
    
        const formQuiz = interaction as IWordQuiz;
        const newKeys = getKeys(formQuiz.answers.length + 2);
        replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);
        replaceKey(formQuiz.formattedSentence, newKeys[newKeys.length - 2]);

        return {
            id: uuidv4(),
            name: interaction.name,
            type: InteractionType.WordQuiz,
            title: newKeys[newKeys.length - 1],
            formattedSentence: newKeys[newKeys.length - 2],
            answers: getAnswerArray(formQuiz.answers, newKeys),
            properties: formQuiz.properties,
        };
    }

    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy
    }
}
