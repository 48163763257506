import AddIcon from "@mui/icons-material/Add";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { Draggable, DraggingStyle, Droppable, NotDraggingStyle } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTake } from "../../app/slices/TrainingSlice";
import { useHelper3D } from "../../hooks/HelperHooks3D";
import { ProfilePictures } from "../../models/Actor";
import Take3D from "../../models/Take3D";
import TakeActorList from "../actor_settings/TakeActorList";
import InteractionPicker from "../interaction_picker/InteractionPicker";
import InteractionCard from "./InteractionCard";
import "./TakeCard3DContent.css";
import PropTakeList from "../prop_take_list/PropTakeList";
import usePropHelper from "../../hooks/PropHelper";
import { useTranslation } from "react-i18next";
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
export default function TakeCard3DContent(props: { take: Take3D; index: number }) {
    const { take } = { ...props };
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const {getInvolvedPropInteractions} = usePropHelper();
    const { t } = useTranslation();

    const isUsingDefaultCamera = take.properties?.find((p) => p.name === "Use Default Camera")?.value === true;

    const rootClassname = take.isExpanded ? "takecard3dcontent-root takecard3dcontent-expanded" : "takecard3dcontent-root takecard3dcontent-collapsed";

    const onClickAddInteraction = () => {
        dispatch(setSelectedTake(take.id));
        setIsOpen(true);

        // TODO: set prop interactions !
        let involvedInteractions = getInvolvedPropInteractions(take);

    };

    const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => {
        return {
            padding: "1px",
            cursor: "pointer",
            ...draggableStyle,
        };
    };

    const mainCameraTitle = <div className="takecard3dcontent-title">{t("camera")}</div>;
    const mainCameraInteraction = (
        <div className="takecard3dcontent-interaction">
            <InteractionCard interaction={take.mainCamera} locId="main_camera" />
        </div>
    );

    const actorTitle = <div className="takecard3dcontent-title">{t("active_actors")}</div>;


    const propTitle = <div className="takecard3dcontent-title">Props</div>;


    const interactionTitle = <div className="flex gap-[10px] p-[10px] text-creator-text text-[24px] bg-white border-b-2 border-solid border-creator-border">
        <LayersOutlinedIcon className={"my-auto"} color={"inherit"} fontSize={"inherit"} />
        <div className={"text-[14px] text-creator-text font-bold"}>{t("interactions")}</div>
    </div>;

    const interactionsdnd = (
        <Droppable droppableId={take.id} type="interaction3d">
            {(provided, snapshot) => {
                return (
                    <div className="flex flex-col py-[5px] gap-[0px] bg-interaction-softBackground" {...provided.droppableProps} ref={provided.innerRef}>
                        {take.interactions.map((interaction, index) => (
                            <Draggable key={interaction.id} draggableId={interaction.id} index={index}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                        <InteractionCard key={index} interaction={interaction} />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                );
            }}
        </Droppable>
    );

    const interactionContent = <div className="">{interactionsdnd}</div>;

    const addInteractionIcon = (
        <div className={take.interactions.length > 0 ? "take3dcardcontent-addinteraction take3dcardcontent-addinteraction-border" : "take3dcardcontent-addinteraction"}>
            <div className="take3dcardcontent-addinteraction-button">
                <IconButton color="inherit" size="small" onClick={onClickAddInteraction}>
                    <AddIcon fontSize="inherit" />
                </IconButton>
            </div>
        </div>
    );

    const camera = (
        <>
            <div className="takecard3dcontent-main-camera-wrapper">
                {mainCameraTitle}
                {!isUsingDefaultCamera && mainCameraInteraction}
                {isUsingDefaultCamera && (
                    <div className="takecard3dcontent-default-camera">
                        <CameraAltOutlinedIcon fontSize="inherit" color="inherit" />
                        <div className="take3dcontent-default-camera-text">Using default camera</div>
                    </div>
                )}
            </div>
        </>
    );

    const actors = (
        <>
            {actorTitle}
            <TakeActorList take={take} index={props.index} />
        </>
    );

    const propList = <>
        {propTitle}
        <PropTakeList take={take} />
    </>

    const interactions = (
        <>
            {interactionTitle}
            {interactionContent}
            {addInteractionIcon}
        </>
    );

    return (
        <>
            <div className={rootClassname}>
                {/*{camera}*/}
                {/*{actors}*/}
                {/*{propList}*/}
                {interactions}
            </div>
            <InteractionPicker open={isOpen} onClose={() => setIsOpen(false)} />
        </>
    );
}
