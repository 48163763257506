import { v4 as uuidv4 } from "uuid";
import { InteractionFactoryEntity, registerInteraction } from "../../features/interaction_factory/InteractionFactory";
import EmotionBubblePreview from "../../features/previews/emotion_bubble/EmotionBubblePreview";
import { InteractionProperty, InteractionPropertyType } from "../InteractionProperty";
import Interaction, { InteractionType } from "./Interaction";
import {IMessageBubble, messageBubbleAnimations} from "./MessageBubble";
import {IAnswer} from "../Quiz";

export const emotionBubbleStyles: string[] = ["Happy", "Panic", "Thumb", "ThumbsDown", "VeryHappy", "Exclame"];

type EmotionBubbleStyle = {
  name: string;
  path: string;
  displayName: string;
};

export const emotionBubbleStylesMapped: EmotionBubbleStyle[] = [
  {
    name: "VeryHappy",
    path: "/emotions/very_happy.png",
    displayName: "Very Happy",
  },
  {
    name: "Panic",
    path: "/emotions/panic.png",
    displayName: "Panic",
  },
  {
    name: "Thumb",
    path: "/emotions/thumb.png",
    displayName: "Thumbs up",
  },
  {
    name: "ThumbsDown",
    path: "/emotions/thumbs_down.png",
    displayName: "Thumbs down",
  },
  {
    name: "Exclame",
    path: "/emotions/exclame.png",
    displayName: "Exclame",
  },
];

export interface IEmotionBubble extends Interaction {
  npcId: string;
  messageBubbleStyle: string;
  lookAt: string;
  animation: string;
}

const requiredEmotionBubbleProperties: InteractionProperty[] = [
  {
    id: "displayTime",
    name: "Display Time",
    type: InteractionPropertyType.number,
    value: 2,
  },
];

export function CreateEmotionBubble(): IEmotionBubble {
  return {
    id: uuidv4(),
    name: "Emotion Bubble",
    animation: messageBubbleAnimations[0],
    lookAt: "",
    messageBubbleStyle: emotionBubbleStylesMapped[0].name,
    npcId: "player",
    type: InteractionType.EmotionBubble,
    properties: requiredEmotionBubbleProperties,
  };
}

export function CreateEmotionBubbleCopy(interaction: IEmotionBubble): IEmotionBubble {
  return {
    id: uuidv4(),
    name: "Emotion Bubble",
    animation: interaction.animation,
    lookAt: interaction.lookAt,
    messageBubbleStyle: interaction.messageBubbleStyle,
    npcId: interaction.npcId,
    type: InteractionType.EmotionBubble,
    properties: interaction.properties,
  };
}

export const emotionBubbleInteraction = (): InteractionFactoryEntity => {
  const component = <EmotionBubblePreview />;

  const create = (getKeys: (amount: number) => string[]): IEmotionBubble => {
    return {
      id: uuidv4(),
      name: "Emotion Bubble",
      animation: messageBubbleAnimations[0],
      lookAt: "",
      messageBubbleStyle: emotionBubbleStylesMapped[0].name,
      npcId: "player",
      type: InteractionType.EmotionBubble,
      properties: requiredEmotionBubbleProperties,
    };
  };
  
  const migrate = (from: number, to: number, interaction: Interaction): IEmotionBubble => {
    return interaction as IEmotionBubble;
  };

  const copy = (interaction: Interaction,
                getKeys: (amount: number) => string[],
                replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IEmotionBubble => {

    const messageBubble = interaction as IEmotionBubble;

    return {
      ...messageBubble,
      id: uuidv4(),
    }
  }
  
  return {
    View: component,
    Create: create,
    Migrate: migrate,
    Copy: copy
  };
};


