import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Vec2D} from "../../models/Vec2D";
import {RootState} from "../Store";
import {TranslatedEnvironmentDTO} from "../../dto/TranslatedEnvironmentDTO";
import {TranslatedEnvironmentContentDTO} from "../../dto/TranslatedEnvironmentContentDTO";
import {Root} from "react-dom/client";

interface EnvironmentState {
    currentEnvironmentId: string;
    name: string;
    environmentThumbnail: string;
    currentGameEnvironment: string;
    interactionPoints: TakePosition[];
    cachedEnvironments: TranslatedEnvironmentsState;
    cachedLeafletData: Record<string, LeafletData>;
}

export enum InteractionPointPositionType {
    npc = 1,
    props = 2,
    camera = 3,
}

export interface TakePosition {
    id: string;
    name: string;
    position: Vec2D;
    actor_positions: InteractionPointPosition[];
    camera_positions: CameraPosition[];
    prop_positions: PropPosition[];
}

export interface InteractionPointPosition {
    id: string;
    name: string;
    type: InteractionPointPositionType;
    position: Vec2D;
}

interface TranslatedEnvironmentsState {
    initialized: boolean;
    translatedEnvironments: TranslatedEnvironmentDTO[];
    totalPages: number;
    totalCount: number;
    translatedEnvironmentsContent: Record<string, TranslatedEnvironmentContentDTO>;
}

export interface CameraPosition {
    id: string;
    name: string;
    position: Vec2D;
    direction: Vec2D;
}

export interface PropPosition {
    id: string;
    name: string;
    position: Vec2D;
    direction: Vec2D;
}

const initialEnvironmentState: EnvironmentState = {
    currentEnvironmentId: "not-set",
    name: "",
    environmentThumbnail: "",
    currentGameEnvironment: "",
    interactionPoints: [],

    cachedEnvironments: {
        initialized: false,
        translatedEnvironments: [],
        totalPages: 0,
        totalCount: 0,
        translatedEnvironmentsContent: {},
    },
    cachedLeafletData: {},
};

// make new environment slice
export const environmentSlice = createSlice({
    name: "environment",
    initialState: initialEnvironmentState,
    reducers: {
        initializeEnvironment: (state, action: PayloadAction<{ id: string, name: string, floorplanUri: string, gameEnvironment: string }>) => {
            state.currentEnvironmentId = action.payload.id;
            state.name = action.payload.name;

            // TODO: enable this again when api is up to date :)
            //state.environmentThumbnail = action.payload.floorplanUri;
            state.environmentThumbnail = "/leaflet";

            state.currentGameEnvironment = action.payload.gameEnvironment;
        },
        setEnvironmentMetadata: (state, action: PayloadAction<TakePosition[]>) => {
            state.interactionPoints = action.payload;
        },
        // ==== translated environments ====
        initializeTranslatedEnvironments: (state, action: PayloadAction<{ totalPages: number, totalCount: number, firstPage: TranslatedEnvironmentDTO[] }>) => {
            // reserve space for all environments
            state.cachedEnvironments.totalPages = action.payload.totalPages;
            state.cachedEnvironments.totalCount = action.payload.totalCount;
            state.cachedEnvironments.translatedEnvironments = new Array(action.payload.totalCount);

            // fill with loading text
            for (var i = 0; i < action.payload.totalCount; i++) {
                state.cachedEnvironments.translatedEnvironments[i] = {id: "loading", name: "Loading...", description: "", thumbnailUri: ""};
            }

            // add first page to the array
            action.payload.firstPage.forEach((env, index) => {
                state.cachedEnvironments.translatedEnvironments[index] = env;
            });

            state.cachedEnvironments.initialized = true;

        },
        updateTranslatedEnvironments: (state, action: PayloadAction<{ pageNumber: number, pageSize: number, page: TranslatedEnvironmentDTO[] }>) => {
            const {pageNumber, pageSize, page} = {...action.payload};

            page.forEach((env, index) => {
                state.cachedEnvironments.translatedEnvironments[(pageNumber - 1) * pageSize + index] = env;
            });
        },
        cleanupTranslatedEnvironments: (state) => {
            state.cachedEnvironments.translatedEnvironments = [];
            state.cachedEnvironments.initialized = false;
        },
        cleanupCurrentEnvironment: (state) => {
            state.currentEnvironmentId = "";
            state.currentGameEnvironment = "";
            state.environmentThumbnail = "";
        },
        // ==== translated environments content ====
        registerTranslatedEnvironmentContent: (state, action: PayloadAction<TranslatedEnvironmentContentDTO>) => {
            state.cachedEnvironments.translatedEnvironmentsContent[action.payload.id] = action.payload;
        },
        // ==== leaflet data ====
        clearCachedLeafletData: (state) => {
            state.cachedLeafletData = {};
        },
        registerLeafletData: (state, action: PayloadAction<{ x: number, y: number, z: number, url: string }>) => {
            const key = `${action.payload.x},${action.payload.y},${action.payload.z}`;
            //state.cachedLeafletData[key] = {url: action.payload.url, coordinates: {x: action.payload.x, y: action.payload.y, z: action.payload.z}};

            state.cachedLeafletData = {
                ...state.cachedLeafletData,
                [key]: {url: action.payload.url, coordinates: {x: action.payload.x, y: action.payload.y, z: action.payload.z}}
            }
        },
    },
});

export const {
    initializeEnvironment,
    setEnvironmentMetadata,
    initializeTranslatedEnvironments,
    updateTranslatedEnvironments,
    cleanupTranslatedEnvironments,
    registerTranslatedEnvironmentContent,
    cleanupCurrentEnvironment,
    clearCachedLeafletData,
    registerLeafletData
} = environmentSlice.actions;

export const selectCurrentEnvironmentThumbnail = (state: RootState) => state.environment.environmentThumbnail;
export const selectEnvironmentInteractionPoints = (state: RootState) => state.environment.interactionPoints;
export const selectTranslatedEnvironments = (state: RootState) => state.environment.cachedEnvironments.translatedEnvironments;
export const selectTranslatedEnvironmentsTotalPages = (state: RootState) => state.environment.cachedEnvironments.totalPages;
export const selectIsTranslatedEnvironmentsInitialized = (state: RootState) => state.environment.cachedEnvironments.initialized;
export const selectTranslatedEnvironmentContent = (state: RootState) => state.environment.cachedEnvironments.translatedEnvironmentsContent;
export const selectCurrentEnvironmentId = (state: RootState) => state.environment.currentEnvironmentId;
export const selectCurrentGameEnvironment = (state: RootState) => state.environment.currentGameEnvironment;
export const selectCachedLeafletData = (state: RootState) => state.environment.cachedLeafletData;
export const selectMaxZoomLevel = (state: RootState) => state.environment.cachedEnvironments.translatedEnvironmentsContent[state.environment.currentEnvironmentId]?.levels;
export default environmentSlice.reducer;
