import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedTake3D, updateSelectedTake, updateSelectedTakeProperty} from "../../app/slices/TrainingSlice";
import {InteractionProperty} from "../../models/InteractionProperty";
import InteractionPropertyView from "../interaction_properties_column/InteractionPropertyView";
import PropertiesColumnHeader from "../properties_column/PropertiesColumnHeader";
import PiCollapsibleSettings from "../../components/PiCollapsibleSettings";
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import PiInputfield from "../../components/PiInputField";
import {useTranslation} from "react-i18next";
import Take from "../../models/Take";
import TakePropertiesActorList from "./TakePropertiesActorList";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import PropertiesColumnBody from "../properties_column/PropertiesColumnBody";
import TakePropertiesPropList from "./TakePropertiesPropList";

export default function Take3DPropertiesColumn() {
    const dispatch = useDispatch();
    const take = useSelector(selectSelectedTake3D);
    const properties = take?.properties;
    const {t} = useTranslation();

    const onChangeTakeName = (newName: string) => {
        dispatch(updateSelectedTake({...take, name: newName} as Take));
    }

    const onChange = (interactionProperty: InteractionProperty) => {
        dispatch(updateSelectedTakeProperty(interactionProperty));
    };

    const takeName = <>
        <PiInputfield label={t("take_name")} value={take?.name ?? ""} placeholder={"Take Name"} onChange={onChangeTakeName}/>
    </>;

    const propMap = properties?.map((property, index) => {
        return <InteractionPropertyView key={index} interactionProperty={property} onChange={onChange}/>;
    });

    const takeTab = <PiCollapsibleSettings text={t("take")} icon={<MovieCreationOutlinedIcon color={"inherit"} fontSize={"inherit"}/>} paddingX paddingY>
        {takeName}
    </PiCollapsibleSettings>;

    const storyProp = properties?.find(p => p.id === "isStory");
    const storyView = storyProp ? <InteractionPropertyView interactionProperty={storyProp} onChange={onChange}/> : <></>;

    const camTab = <PiCollapsibleSettings text={t("camera")} icon={<CameraAltOutlinedIcon color={"inherit"} fontSize={"inherit"}/>} paddingX paddingY>
        {storyView}
    </PiCollapsibleSettings>

    return (
        <>
            <PropertiesColumnHeader text={take?.name ?? "Take"}/>
            <PropertiesColumnBody>
                {takeTab}
                {camTab}
                <TakePropertiesActorList/>
                <TakePropertiesPropList/>
            </PropertiesColumnBody>
        </>
    );
}
