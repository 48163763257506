import {useTrainingLocalization} from "../../../trainingLocalization/TrainingLocalizationHooks";
import {useDebouncedCallback} from "use-debounce";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectCurrentLanguage} from "../../../../app/slices/TrainingLocalizationSlice";
import {useEffect, useState} from "react";

export default function PiBasicQuizAnswerTextfieldV2(props: { localizationKey: string }) {
    const {localizationKey} = {...props};
    const {getValue, changeValue} = useTrainingLocalization();
    const {t} = useTranslation();
    const [currentValue, setCurrentValue] = useState<string>(getValue(localizationKey));
    const currLang = useSelector(selectCurrentLanguage);

    useEffect(() => {
        setCurrentValue(getValue(localizationKey));
    }, [currLang]);
    
    const debounced = useDebouncedCallback((value: string) => {
        changeValue(localizationKey, value);
    }, 500);

    const onInputChange = (newStr: string) => {
        debounced(newStr);
        setCurrentValue(newStr);
    };
    
    return <div className={"w-full h-full flex"}>
        <input
            className={"w-full my-auto h-full text-[14px] font-medium truncate whitespace-nowrap bg-transparent text-interaction-primary focus:outline-none placeholder:text-[14px]" +
                " placeholder:text-interaction-text-placeholder "}
            value={currentValue}
            onChange={(e) => onInputChange(e.target.value)} placeholder={t("answer_placeholder") ?? ""}/>
    </div>
}