import { v4 as uuidv4 } from "uuid";
import { InteractionFactoryEntity, registerInteraction } from "../../features/interaction_factory/InteractionFactory";
import ClickRegionOrderQuizPreview from "../../features/previews/click_region_order_quiz/ClickRegionOrderQuizPreview";
import { CreateCustomClickRegion, IClickRegion } from "./ClickRegion";
import Interaction, {InteractionType, Marker} from "./Interaction";
import {IAnswer} from "../Quiz";

export interface IClickRegionOrderQuiz extends Interaction {
  title: string;
  clickregions: IClickRegion[];
}

export function CreateClickRegionOrderQuiz(key: string): IClickRegionOrderQuiz {
  return {
    id: uuidv4(),
    name: "Click Region Order Quiz",
    type: InteractionType.ClickRegionOrderQuiz,
    title: key,
    clickregions: [CreateCustomClickRegion(0.4, 0.5, 0.1, 0.1), CreateCustomClickRegion(0.6, 0.5, 0.1, 0.1)],
  };
}

export function MigrateClickRegionOrderQuiz(from: number, to: number, interaction: IClickRegionOrderQuiz): IClickRegionOrderQuiz {
  return interaction;
}

export const clickRegionOrderQuizInteraction = (): InteractionFactoryEntity => {
  const component = <ClickRegionOrderQuizPreview />;
  const create = (getKeys: (amount: number) => string[]): IClickRegionOrderQuiz => {
    const keys = getKeys(1);
    return {
      id: uuidv4(),
      name: "Click Region Order Quiz",
      type: InteractionType.ClickRegionOrderQuiz,
      title: keys[0],
      clickregions: [CreateCustomClickRegion(0.4, 0.5, 0.1, 0.1), CreateCustomClickRegion(0.6, 0.5, 0.1, 0.1)],
    };
  };
  const migrate = (from: number, to: number, interaction: Interaction): IClickRegionOrderQuiz => {
    return interaction as IClickRegionOrderQuiz;
  };

  const copy = (interaction: Interaction,
                getKeys: (amount: number) => string[],
                replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IClickRegionOrderQuiz => {
    return create(getKeys);
  }
  
  return {
    View: component,
    Create: create,
    Migrate: migrate,
    Copy: copy
  };
};


