import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { IconButton, Switch } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedTake, updateSelectedTake } from "../../app/slices/TrainingSlice";
import { useDialogToggle } from "../../hooks/PiDialogHook";
import Take360 from "../../models/Take360";
import EditBubbleDialog from "./EditBubbleDialog";
import "./TrainingPreviewBar.css";
import { useTranslation } from "react-i18next";
export default function TrainingPreviewBar() {
  const selectedTake = useSelector(selectSelectedTake);
  const [isOpen, onClose, onOpen] = useDialogToggle();
  const dispatch = useDispatch();

  const name = (
    <div className="trainingpreviewbar-name">
      <ImageOutlinedIcon fontSize="large" />
      <span>{selectedTake?.name}</span>
    </div>
  );

  const onSwitchToggle = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (selectedTake) {
      var updatedTake: Take360 = { ...selectedTake };
      updatedTake.display360 = checked;
      dispatch(updateSelectedTake(updatedTake));
    }
  };

  const {t} = useTranslation();

  const toggle360 = (
    <div className="trainingpreviewbar-toggle">
      <div className="trainingpreviewbar-toggle-content">
        <div className="trainingpreviewbar-toggle-offset">
          <Switch checked={selectedTake?.display360} onChange={onSwitchToggle} />
        </div>
        <span className="trainingpreviewbar-toggle-content-name">{t("360_preview")}</span>
      </div>
    </div>
  );

  const editBtn = (
    <div className="trainingpreviewbar-editbutton">
      <IconButton color="inherit" size="large" onClick={onOpen}>
        <SettingsOutlinedIcon fontSize="inherit" />
      </IconButton>
    </div>
  );

  const editDialog = <EditBubbleDialog open={isOpen} onClose={onClose} />;

  return (
    <div className="trainingpreviewbar-root">
      {name}
      {selectedTake?.is360Image && toggle360}
      {editBtn}
      {editDialog}
    </div>
  );
}
