import {PropMetadata} from "../../dto/PropMetadata";
import classNames from "classnames";
import {TranslatedPropDTO} from "../../dto/TranslatedPropDTO";
import {useSelector} from "react-redux";
import {selectPropCategories} from "../../app/slices/PropLibrarySlice";

export default function PropLibraryCard(props: { prop: TranslatedPropDTO, selected?: boolean, onClick: () => void }) {
    const {prop, selected, onClick} = {...props};
    const categories = useSelector(selectPropCategories);
    
    const myCategoriesMapped = prop.categories.map(categoryId => {
        const download = categories.find(category => category.id === categoryId.id);
        if(!download) return;
        
        return <img key={categoryId.id} className={"w-[24px] h-[24px] object-fit"} src={download.downloadUri} alt={categoryId.id} />;
    });
    
    
    return (<div className={classNames({
        "w-full h-[190px] piLg:h-[264px] rounded-[5px] cursor-pointer flex flex-col drop-shadow-sm border-solid border-2 select-none": true,
        "border-creator-border": !selected,
        "border-creator-primary": selected
    })} onClick={onClick}>
        <div className={"w-full h-[96px] piLg:h-[170px] bg-interaction-softBackground rounded-t-[5px] p-[32px]"}>
            <img src={prop.downloadUri} className={"w-full h-full object-contain rounded-lg"} alt={prop.name}/>
        </div>
        <div className={"bg-white flex-1 rounded-b-[5px] text-center py-[16px] px-[16px]"}>
            <div className={"w-full h-full flex flex-col gap-[10px] justify-start"}>
                <div className={"text-left text-creator-text text-[16px] font-bold truncate"}>{prop.name}</div>
                <div className={"flex gap-[10px]"}>{myCategoriesMapped}</div> 
            </div>
        </div>
    </div>);
}