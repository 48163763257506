import React from "react";
import {useSelector} from "react-redux";
import {selectActors, selectSelectedTake3D} from "../../app/slices/TrainingSlice";
import PiDropdownV2 from "../../components/PiDropdownV2";
import {PiPopperMenuProps} from "../../components/PiPopper";
import {ActorState} from "../../models/ActorState";
import {PiInteractionDropdown} from "../../components/PiInteractionDropdown";
import ActorAvatar from "../../components/actors/ActorAvatar";
import PiCreatorDropdown from "../../components/PiCreatorDropdown";
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
export default function ActorStateLookatDropdown(props: { actorState: ActorState; onChange: (lookatId: string) => void }) {
    const {actorState, onChange} = {...props};
    const actorsInSelectedTake = useSelector(selectSelectedTake3D)?.actors.filter(a => a.actorId !== actorState.actorId);
    const allActors = useSelector(selectActors).filter(a => a.id !== actorState.actorId);
    const selectedActor = allActors.find(a => a.id === actorState.lookat);

    const items = actorsInSelectedTake?.map((actor) => {
        const myActor = allActors.find((a) => a.id === actor.actorId);
        return <div key={actor.id} className={"flex gap-[10px] py-[8px]"} onClick={() => onChange(actor.actorId)}>
            <ActorAvatar actor={myActor} theme={"creator-selected"}/>
            <div className={"my-auto text-[14px] text-creator-text font-medium"}>
                {myActor!.workingName}
            </div>
        </div>
    });

    const trigger = selectedActor ? <div className={"flex gap-[10px] h-full w-full"}>
        <ActorAvatar actor={selectedActor} theme={"creator-selected"}/>
        <div className={"my-auto"}>
            {selectedActor!.workingName}
        </div>
    </div> : <div className={"flex text-left text-[14px] text-creator-text h-full w-full"}>
        <div className={"my-auto flex gap-[10px]"}>
            <PersonOffOutlinedIcon className={"my-auto"}/>
            <span className={"my-auto"}>
                No Actor Selected
            </span>
        </div>
    </div>;
    
    const newDropdown = <PiCreatorDropdown label={"Lookat"} trigger={trigger} items={items as JSX.Element[]}/>

    return <>{newDropdown}</>;
}
