import {IAnswer, IFormQuiz} from "../Quiz";
import Interaction, {InteractionType} from "./Interaction";
import {v4 as uuidv4} from "uuid";

import {InteractionFactoryEntity, registerInteraction} from "../../features/interaction_factory/InteractionFactory";

import DialogQuizPreview from "../../features/previews/dialog_quiz/DialogQuizPreview";

export interface IDialogQuiz extends IFormQuiz {
    playerGuid: string;
}

export function CreateDialogQuiz(keys: string[]): IDialogQuiz {
    return {
        id: uuidv4(),
        name: "Dialog Quiz",
        type: InteractionType.DialogQuiz,
        title: keys[0],
        answers: [
            {id: uuidv4(), correct: true, text: keys[1]},
            {id: uuidv4(), correct: false, text: keys[2]},
        ],
        playerGuid: "player",
    };
}

export function CreateDialogQuizCopy(titleKey: string, answers: IAnswer[], playerGuid: string): IDialogQuiz {
    return {
        id: uuidv4(),
        name: "Dialog Quiz",
        type: InteractionType.DialogQuiz,
        title: titleKey,
        answers: answers,
        playerGuid: playerGuid,
    };
}

export function MigrateDialogQuiz(from: number, to: number, interaction: IDialogQuiz): IDialogQuiz {
    return interaction;
}

export const dialogQuizInteraction = (): InteractionFactoryEntity => {

    const component = <DialogQuizPreview/>;

    const create = (getKeys: (amount: number) => string[]): IDialogQuiz => {
        const keys = getKeys(3);
        return {
            id: uuidv4(),
            name: "Dialog Quiz",
            type: InteractionType.DialogQuiz,
            title: keys[0],
            answers: [
                {id: uuidv4(), correct: true, text: keys[1]},
                {id: uuidv4(), correct: false, text: keys[2]},
            ],
            playerGuid: "player",
        };
    };
    const migrate = (from: number, to: number, interaction: Interaction): IDialogQuiz => {
        return interaction as IDialogQuiz;
    }

    const copy = (interaction: Interaction,
                  getKeys: (amount: number) => string[],
                  replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IDialogQuiz => {
        const formQuiz = interaction as IDialogQuiz;
        const newKeys = getKeys(formQuiz.answers.length + 1);
        replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);
        return {
            id: uuidv4(),
            name: interaction.name,
            type: InteractionType.DialogQuiz,
            title: newKeys[newKeys.length - 1],
            answers: getAnswerArray(formQuiz.answers, newKeys),
            playerGuid: formQuiz.playerGuid,
            properties: formQuiz.properties,
        };
    }
    
    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy
    }
}


