import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImageBounds } from "../../features/training_preview/TrainingBubbleViewport";
import { Actor } from "../../models/Actor";
import { ActorState } from "../../models/ActorState";
import Interaction from "../../models/interactions/Interaction";
import { InteractionProperty } from "../../models/InteractionProperty";
import { Prop } from "../../models/Prop";
import Take from "../../models/Take";
import Take360 from "../../models/Take360";
import Take3D from "../../models/Take3D";
import { RootState } from "../Store";
import { TrainingSliceStrategy } from "./strategies/TrainingSliceStrategy";
import { TrainingSliceStrategy360 } from "./strategies/TrainingSliceStrategy360";
import { TrainingSliceStrategy3D } from "./strategies/TrainingSliceStrategy3D";

export enum TrainingType {
    Training3D = 0,
    Training360 = 1,
    NotSet = -1,
}

export interface TrainingState {
    trainingName: string; // re-usable
    trainingDescription: string; // re-usable

    takes360: Take360[]; // different
    takes3D: Take3D[]; // different

    selectedTake: string; // re-usable
    selectedElementId: string; // re-usable
    selectedElementIsTake: boolean; // re-usable
    selectedElementIsInteraction: boolean; // re-usable

    filter: string; // re-usable

    viewportBounds: ImageBounds | undefined; // re-usable
    imageBounds: ImageBounds | undefined; // re-usable

    isPlaying: boolean; // re-usable
    testData: string; // re-usable

    referenceLanguage: string; // shortcode, re-usable

    isDirty: boolean; // re-usable

    trainingType: TrainingType; // re-usable

    actors: Actor[]; // re-usable => these are the actor presets
    selectedActor: string; // re-usable

    props: Prop[]; // a list of props used in the training
    selectedProp: string; // the currently selected prop
}

const initialState: TrainingState = {
    trainingName: "",
    trainingDescription: "",
    takes360: [],
    takes3D: [],
    selectedTake: "",
    selectedElementId: "",
    selectedElementIsTake: true,
    selectedElementIsInteraction: false,
    filter: "",
    isPlaying: false,
    viewportBounds: undefined,
    imageBounds: undefined,
    testData: "",
    referenceLanguage: "",
    isDirty: false,
    trainingType: TrainingType.NotSet,
    actors: [],
    selectedActor: "",
    props: [],
    selectedProp: ""
};

export const selectTakeIndexById = (takes: Take[], takeId: string) => {
    return takes.findIndex((take) => take.id === takeId);
};

export const selectSelectedTakeActors = (state: RootState) => {
    //At least the player character is always filled in
    const activeTakeActors: Actor[] = [state.training.actors.find((actor) => actor.id === "player")!];
    //Find the currently selected take
    const selectedTake: Take3D | undefined = state.training.takes3D.find((take) => take.id === state.training.selectedTake);

    //Match current take actor states with global list of actors
    if (selectedTake !== undefined) {
        const allActors: Actor[] = state.training.actors;

        selectedTake.actors.forEach((actorState) => {
            const foundActor = allActors.find((a) => a.id === actorState.actorId);
            if (foundActor) activeTakeActors.push(foundActor);
        });
    }

    return activeTakeActors;
}

export const selectInteractionIndexById = (take: Take, interactionId: string) => {
    return take.interactions.findIndex((interaction) => interaction.id === interactionId);
};

var strategy: TrainingSliceStrategy | undefined = undefined;

export const trainingSlice = createSlice({
    name: "training",
    initialState: initialState,
    reducers: {
        createNewTraining: (
            state,
            action: PayloadAction<{
                trainingname: string;
                trainingDescription: string;
                trainingType: TrainingType;
            }>
        ) => {
            state.trainingName = action.payload.trainingname;
            state.trainingDescription = action.payload.trainingDescription;
            state.takes360 = [];
        },
        setTrainingName: (state, action: PayloadAction<string>) => {
            state.trainingName = action.payload;
        },
        setTrainingDescription: (state, action: PayloadAction<string>) => {
            state.trainingDescription = action.payload;
        },
        setTrainingType: (state, action: PayloadAction<TrainingType>) => {
            state.trainingType = action.payload;
            strategy = action.payload === TrainingType.Training360 ? TrainingSliceStrategy360 : TrainingSliceStrategy3D;
        },
        setSelectedTake: (state, action: PayloadAction<string>) => {
            strategy?.setSelectedTake(state, action.payload);
        },
        setSelectedInteraction: (state, action: PayloadAction<string>) => {
            strategy?.setSelectedInteraction(state, action.payload);
        },
        setIsPlaying: (state, action: PayloadAction<{ isPlaying: boolean; data: string }>) => {
            state.isPlaying = action.payload.isPlaying;
            state.testData = action.payload.data;
        },
        addNewTake: (
            state,
            action: PayloadAction<{
                name?: string;
                assetId?: string;
            }>
        ) => {
            strategy?.addNewTake(state, action.payload.name, action.payload.assetId);
        },
        addTakeCopy: (
            state,
            action: PayloadAction<Take3D>
        ) => {
            strategy?.addTakeCopy(state, action.payload);
        },
        addInteractionToTake: (state, action: PayloadAction<{ takeId: string; interaction: Interaction }>) => {
            strategy?.addInteractionToTake(state, action.payload.interaction, action.payload.takeId);
        },
        addInteractionToSelectedTake: (state, action: PayloadAction<Interaction>) => {
            strategy?.addInteractionToSelectedTake(state, action.payload);
        },
        addInteractionCopy: (state, action: PayloadAction<Interaction>) => {
            strategy?.addInteractionCopy(state, action.payload);
        },
        deleteInteraction: (state, action: PayloadAction<Interaction>) => {
            strategy?.deleteInteraction(state, action.payload);
        },
        setFilter: (state, action: PayloadAction<string>) => {
            state.filter = action.payload;
        },
        reorderTakes: (state, action: PayloadAction<{ startIndex: number; endIndex: number }>) => {
            const { startIndex, endIndex } = { ...action.payload };
            strategy?.reorderTakes(state, startIndex, endIndex);
        },
        reorderInteractions: (state, action: PayloadAction<{ startTakeId: string; destinationTakeId: string; startIndex: number; endIndex: number }>) => {
            const { startTakeId, destinationTakeId, startIndex, endIndex } = { ...action.payload };

            strategy?.reorderInteractions(state, startTakeId, destinationTakeId, startIndex, endIndex);
        },
        updateSelectedTake: (state, action: PayloadAction<Take>) => {
            strategy?.updateSelectedTake(state, action.payload);
        },
        updateSelectedTakeProperty: (state, action: PayloadAction<InteractionProperty>) => {
            strategy?.updateSelectedTakeProperty(state, action.payload);
        },
        updateSelectedInteraction: (state, action: PayloadAction<Interaction>) => {
            strategy?.updateSelectedInteraction(state, action.payload);
        },
        updateSelectedInteractionProperty: (state, action: PayloadAction<InteractionProperty>) => {
            strategy?.updateSelectedInteractionProperty(state, action.payload);
        },
        setViewportBounds: (state, action: PayloadAction<ImageBounds>) => {
            state.viewportBounds = action.payload;
        },
        setImageBounds: (state, action: PayloadAction<ImageBounds>) => {
            state.imageBounds = action.payload;
        },
        setTakes: (state, action: PayloadAction<Take[]>) => {
            strategy?.setTakes(state, action.payload);
        },
        deleteTake: (state, action: PayloadAction<string>) => {
            if (!action.payload) return;
            strategy?.deleteTake(state, action.payload);
        },
        clearTakes: (state) => {
            state.takes360 = [];
        },
        setReferenceLanguage: (state, action: PayloadAction<string>) => {
            state.referenceLanguage = action.payload;
        },
        setIsDirty: (state) => {
            state.isDirty = true;
        },
        resetIsDirty: (state) => {
            state.isDirty = false;
        },
        loadActors: (state, action: PayloadAction<Actor[]>) => {
            state.actors = action.payload;
            if (action.payload.length > 0) state.selectedActor = action.payload[0].id;
        },
        addActor: (state, action: PayloadAction<Actor>) => {
            state.actors.push(action.payload);
            state.selectedActor = action.payload.id;

            state.isDirty = true;
        },
        updateActor: (state, action: PayloadAction<Actor>) => {
            const index = state.actors.findIndex((actor) => actor.id === action.payload.id);
            if (index > -1) {
                state.actors[index] = action.payload;
                state.isDirty = true;
            }
        },
        setSelectedActor: (state, action: PayloadAction<string>) => {
            state.selectedActor = action.payload;
        },
        deleteActor: (state, action: PayloadAction<string>) => {
            state.actors = state.actors.filter((actor) => actor.id !== action.payload);
            state.isDirty = true;
        },
        loadPropPresets: (state, action: PayloadAction<Prop[]>) => {
            state.props = action.payload;
        },
        addPropPreset: (state, action: PayloadAction<Prop>) => {
            state.props.push(action.payload);
            state.selectedProp = action.payload.id;

            state.isDirty = true;
        },
        setSelectedPropPreset: (state, action: PayloadAction<string>) => {
            state.selectedProp = action.payload;
        },
        deletePropPreset: (state, action: PayloadAction<string>) => {
            state.selectedProp = "";
            state.props = state.props.filter((prop) => prop.id !== action.payload);
        },
        updateSelectedActorState: (state, action: PayloadAction<ActorState>) => {
            strategy?.updateSelectedActorState(state, action.payload);
            state.isDirty = true;
        },
        updateSelectedPropPreset: (state, action: PayloadAction<Prop>) => {
            const index = state.props.findIndex((prop) => prop.id === state.selectedProp);
            if (index === -1) return;
            state.props[index] = action.payload;
        },
        addPropStateToSelectedTake: (state, action: PayloadAction<{ instanceId: string, positionId: string }>) => {
            strategy?.addPropToSelectedTake(state, action.payload.instanceId, action.payload.positionId);
        },
        removePropStateFromSelectedTake: (state, action: PayloadAction<string>) => {
            strategy?.removePropFromSelectedTake(state, action.payload);
        },

    },
});

export const {
    createNewTraining,
    setTrainingName,
    setTrainingDescription,
    setTrainingType,
    setSelectedTake,
    setSelectedInteraction,
    setIsPlaying,
    addNewTake,
    addTakeCopy,
    addInteractionToTake,
    addInteractionToSelectedTake,
    addInteractionCopy,
    deleteInteraction,
    setFilter,
    reorderTakes,
    reorderInteractions,
    updateSelectedTake,
    updateSelectedTakeProperty,
    updateSelectedInteraction,
    updateSelectedInteractionProperty,
    setViewportBounds,
    setImageBounds,
    setTakes,
    deleteTake,
    clearTakes,
    setReferenceLanguage,
    setIsDirty,
    resetIsDirty,
    loadActors,
    addActor,
    updateActor,
    loadPropPresets,
    addPropPreset,
    deletePropPreset,
    setSelectedPropPreset,
    setSelectedActor,
    deleteActor,
    updateSelectedActorState,
    addPropStateToSelectedTake,
    removePropStateFromSelectedTake,
    updateSelectedPropPreset
} = trainingSlice.actions;

// selectors
export const selectTrainingName = (state: RootState) => state.training.trainingName;

export const selectTrainingType = (state: RootState) => state.training.trainingType;

export const selectTrainingDescription = (state: RootState) => state.training.trainingDescription;

export const selectTakes = (state: RootState) => state.training.takes360;

export const selectTakes3D = (state: RootState) => state.training.takes3D;

export const selectFilteredTakes = (state: RootState) => {
    const takes = state.training.takes360;
    const filter = state.training.filter;
    const filteredTakes = takes.filter((take) => {
        return take.name.toLowerCase().includes(filter.toLowerCase());
    });

    return filteredTakes;
};

export const selectTakesMap = (state: RootState) => state.training.takes360;

export const selectSelectedTakeId = (state: RootState) => state.training.selectedTake;

export const selectSelectedTake = (state: RootState) => {
    return state.training.takes360.find((take) => take.id === state.training.selectedTake);
};

export const selectSelectedTake3D = (state: RootState) => {
    return state.training.takes3D.find((take) => take.id === state.training.selectedTake);
};

export const selectSelectedElementId = (state: RootState) => state.training.selectedElementId;
export const selectSelectedElementIsTake = (state: RootState) => state.training.selectedElementIsTake;

export const selectSelectedElementIsInteraction = (state: RootState) => state.training.selectedElementIsInteraction;

export const selectSelectedElement = (state: RootState): Take | Interaction | ActorState | undefined => {
    return strategy?.selectSelectedElement(state);
};

export const selectFilter = (state: RootState) => state.training.filter;

export const selectIsTeleportAllowed = (takes: Take360[], tpId: string) => {
    const parent = takes.find((take) => take.teleportMarker.id === tpId);
    if (parent) {
        if (parent.automaticTransition) return false;
        const index = takes.findIndex((take) => take.id === parent.id);
        if (index > -1 && index < takes.length - 1) return true;
    }

    return false;
};

export const selectIsPlaying = (state: RootState) => state.training.isPlaying;
export const selectTestData = (state: RootState) => state.training.testData;

export const selectReferenceLanguage = (state: RootState) => state.training.referenceLanguage;
export const selectIsDirty = (state: RootState) => state.training.isDirty;

export const selectViewportBounds = (state: RootState) => state.training.viewportBounds;
export const selectImageBounds = (state: RootState) => state.training.imageBounds;

export const selectActors = (state: RootState) => state.training.actors;
export const selectSelectedActorId = (state: RootState) => state.training.selectedActor;
export const selectSelectedActor = (state: RootState) => state.training.actors.find((actor) => actor.id === state.training.selectedActor);
export const selectPlayerActor = (state: RootState) => state.training.actors.find((actor) => actor.id === "player");

export const selectSelectedElementType = (state: RootState) => strategy?.selectSelectedElementType(state.training);

export const selectAllPropPresets = (state: RootState) => state.training.props;
export const selectSelectedPropPresetId = (state: RootState) => state.training.selectedProp;

export default trainingSlice.reducer;
