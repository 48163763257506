import AddIcon from "@mui/icons-material/Add";
import classNames from "classnames";

export default function PiBasicQuizAddAnswerButtonV2(props: { onClick: () => void, disabled?: boolean }) {

    const {onClick, disabled} = {...props};

    return <button
        className={classNames({
            "w-full h-[68px] flex bg-interaction-softBackground border-dashed border-b-2 border-interaction-primary ": true,
            "hover:bg-interaction-primary-washed cursor-pointer": !disabled,
            "cursor-not-allowed": disabled,
        })}
        disabled={disabled} onClick={onClick}>
        <div className={classNames({
            "m-auto w-[36px] h-[36px] flex rounded-full text-white": true,
            "bg-interaction-primary": !disabled,
            "bg-interaction-disabled": disabled,
        })}>
            <AddIcon className={"m-auto"}/>
        </div>
    </button>
}