import {useSelector} from "react-redux";
import {selectPropCategories, selectPropInteractions, selectSelectedProp, selectSelectedPropContent} from "../../app/slices/PropLibrarySlice";
import PiButton from "../../components/PiButton";
import PropLibraryCategoryLabel from "./PropLibraryCategoryLabel";
import PropLibraryInteractionLabel from "./PropLibraryInteractionLabel";

export default function PropLibraryDetails(props: { onAddPropClick: () => void }) {
    const {onAddPropClick} = {...props};
    const selectedProp = useSelector(selectSelectedProp);
    const propContent = useSelector(selectSelectedPropContent);
    const categories = useSelector(selectPropCategories);
    const interactions = useSelector(selectPropInteractions);

    const myCategoriesMapped = selectedProp?.categories.map(category => {
        const result = categories.find(cat => cat.id === category.id);
        if(!result) return;
        return <PropLibraryCategoryLabel key={category.id} category={result}/>
    });

    const myInteractionsMapped = propContent?.interactions.map(interaction => {
        return <PropLibraryInteractionLabel key={interaction.id} interactionName={interaction.name}/>;
    });

    return <div className={"relative w-full h-full bg-interaction-softBackground rounded-[5px] p-[14px] select-none"}>
        {selectedProp &&
            <div className={"flex flex-col gap-[16px] overflow-y-auto"}>
                <span className={"text-creator-text font-medium text-[22px]"}>{selectedProp.name}</span>
                <div className={"bg-[#DAE0E6] rounded-[5px] p-[16px]"}>
                    <img src={selectedProp.downloadUri} alt={selectedProp.name} className={"w-full h-[96px] piLg:h-[256px] object-contain rounded-lg drop-shadow-xl"}/>
                </div>
                <div className={"w-full flex flex-col gap-[16px]"}>
                    <div className={"text-[16px] font-bold text-creator-text"}>Categories</div>
                    <div className={"flex gap-[10px]"}>
                        {myCategoriesMapped}
                    </div>
                </div>
                <div className={"w-full flex flex-col gap-[16px]"}>
                    <div className={"text-[16px] font-bold text-creator-text"}>Available Interactions</div>
                    <div className={"flex gap-[10px]"}>
                        {myInteractionsMapped}
                    </div>
                </div>
            </div>
        }
        {selectedProp &&
            <div className={"absolute bottom-[16px] left-1/2 w-[183px] transform -translate-x-1/2"}>
                <PiButton variant={"piBlue"} onClick={onAddPropClick}>Add Prop</PiButton>
            </div>
        }
    </div>
}