import { v4 as uuidv4 } from "uuid";
import {
  InteractionFactoryEntity,
  registerInteraction,
} from "../../features/interaction_factory/InteractionFactory";
import PiBasicQuizPreview from "../../features/previews/PiBasicQuizPreview";
import { GetMigratedProperties } from "../../features/project_migration/InteractionMigrationHelper";
import { requiredGlobalQuizProperties } from "../GlobalQuizProperty";
import { InteractionProperty } from "../InteractionProperty";
import { IAnswer, IFormQuiz } from "../Quiz";
import Interaction, { InteractionType } from "./Interaction";

export const requiredMultipleChoiceProperties: InteractionProperty[] = [
  ...requiredGlobalQuizProperties,
];

export function CreateMultipleChoiceQuiz(keys: string[]): IFormQuiz {
  return {
    id: uuidv4(),
    name: "Multiple Choice Quiz",
    type: InteractionType.MultipleChoiceQuiz,
    title: keys[0],
    answers: [
      { id: uuidv4(), correct: true, text: keys[1] },
      { id: uuidv4(), correct: false, text: keys[2] },
    ],
    properties: requiredMultipleChoiceProperties,
  };
}

export function CreateMultipleChoiceQuizCopy(
  titleKey: string,
  answers: IAnswer[],
  properties: InteractionProperty[] | undefined
): IFormQuiz {
  return {
    id: uuidv4(),
    name: "Multiple Choice Quiz",
    type: InteractionType.MultipleChoiceQuiz,
    title: titleKey,
    answers: answers,
    properties: properties,
  };
}

export function MigrateMultipleChoiceQuiz(
  from: number,
  to: number,
  interaction: IFormQuiz
): IFormQuiz {
  if (interaction.properties === undefined)
    return { ...interaction, properties: requiredMultipleChoiceProperties };

  var newProperties: InteractionProperty[] = GetMigratedProperties(
    requiredMultipleChoiceProperties,
    interaction.properties
  );
  console.log(
    "[MultipleChoiceQuiz] migrated " +
      (newProperties.length - interaction.properties.length) +
      " properties"
  );

  return { ...interaction, properties: newProperties };
}

export const multipleChoiceQuizInteraction = (): InteractionFactoryEntity => {
  const component = <PiBasicQuizPreview />;

  const create = (getKeys: (amount: number) => string[]): IFormQuiz => {
    const keys = getKeys(3);

    return {
      id: uuidv4(),
      name: "Multiple Choice Quiz",
      type: InteractionType.MultipleChoiceQuiz,
      title: keys[0],
      answers: [
        { id: uuidv4(), correct: true, text: keys[1] },
        { id: uuidv4(), correct: false, text: keys[2] },
      ],
      properties: requiredMultipleChoiceProperties,
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IFormQuiz => {
    const quizData = interaction as IFormQuiz; // need to cast

    if (interaction.properties === undefined) {
      console.log(
        "[MultipleChoiceQuiz] migrated " +
          requiredMultipleChoiceProperties.length +
          " properties"
      );
      return { ...quizData, properties: requiredMultipleChoiceProperties };
    }

    var newProperties: InteractionProperty[] = GetMigratedProperties(
      requiredMultipleChoiceProperties,
      interaction.properties
    );
    console.log(
      "[MultipleChoiceQuiz] migrated " +
        (newProperties.length - interaction.properties.length) +
        " properties"
    );

    return { ...quizData, properties: newProperties };
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): IFormQuiz => {
    const formQuiz = interaction as IFormQuiz;
    const newKeys = getKeys(formQuiz.answers.length + 1);
    replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);
    return {
      id: uuidv4(),
      name: interaction.name,
      type: InteractionType.MultipleChoiceQuiz,
      title: newKeys[newKeys.length - 1],
      answers: getAnswerArray(formQuiz.answers, newKeys),
      properties: formQuiz.properties,
    };
  };

  return {
    View: component,
    Create: create,
    Migrate: migrate,
    Copy: copy,
  };
};
